
import { FilmItem } from './FilmItem';
export interface FilmSize {
  id: string;
  name: string;
  code: string;
  abbreviation: string;
  order: number;
  orderType: string;
  film_types: {
    id: string;
    name: string;
    code: string;
    order: number;
    film_item: FilmItem;
  }[];
  _embedded: {
    style: any;
  }
}
