import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {UserService} from 'app/home/user/user.service';
import {Router, ActivatedRouteSnapshot, ActivatedRoute, RouterStateSnapshot} from '@angular/router';
import {User} from 'app/core/objects/models';
import {Utils} from 'app/core/helper/Utils';
import * as menuConstants from 'app/core/constant/menu-constant';

@Injectable({
  providedIn: 'root'
})
export class RouterGuardService {

  constructor(public userService: UserService, public router: Router, protected route: ActivatedRoute) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // this will be passed from the route config
    // on the data property

    let currentUser = this.userService.user;
    if (!currentUser) {
      currentUser = <any>{
        _embedded: {
          resources: [
            state.url
          ]
        }
      }
    }

    if (state.url !== '/home' && currentUser._embedded.resources.every(i => !state.url.includes(i))) {
      this.router.navigate(['/error/403']);
      return false;
    }
    this.updateBreadcrumb(state.url, currentUser, route)
    return true;
  }

  updateBreadcrumb(url: string, user: User, route: ActivatedRouteSnapshot) {

    if (route.data.breadcrumb) {
      Object.keys(route.data.breadcrumb).forEach(key => {
        if (route.params[key]) {
          url = url.replace(route.params[key], this.userService.breadcrumbParams[route.params[key]]
            || route.data.breadcrumb[key]);
        }
      });
    } else {
      this.userService.breadcrumbParams = {};
    }

    const items = url.split('/');

    this.userService.breadcrumbStack = items.filter(Boolean).map(i => {
      if (i === 'home') {
        return {label: '', icon: 'icon icon-home', routerLink: '/home'};
      }
      return {label: _.upperFirst(i.replace(/-/g, ' '))};
    });
  }


}
