import {Debt} from './Debt';

export class Report {
  id: string;
  sub_total: number;
  total_price: number;
  total_rolls: number;
  discount: any;
  film_income: number;
  coffee_income: number;
  note: string;
  from_time: Date;
  to_time: Date;
  location: string;
  debts: Debt[];
  postage: any[];
  methods: {
    cash: { total: number, discount: number, subTotal: number, debt: number };
    bankTransfer: { total: number, discount: number, subTotal: number, debt: number };
    other: { total: number, discount: number, subTotal: number, debt: number };
    unpaid: { total: number, discount: number, subTotal: number, debt: number };
  };
  details: [
    {
      _embedded: any;
      id: string;
      report_id: string;
      film_size: string;
      film_type: string;
      option: string;
      type: string;
      quantity: number;
      includedOrders: string[];
      total: number;
      sub_total: number;
      discount: number;
      orders: any[];
      mandatory: boolean;
    }
  ];
  closable: boolean;
  creator: any;
}
