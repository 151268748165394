import { UntypedFormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ValidatorObj } from './validator-obj';

export class FormControlDecorator {
  static create<T>(initialValue: T, validators?: ValidatorFn | ValidatorObj | Array<(ValidatorFn | ValidatorObj)>) {
    const formControl = new UntypedFormControl(initialValue);

    if (validators) {
      this.setValidators(formControl, validators);
    }

    return formControl;
  }

  static setValidators(formControl: AbstractControl, validators?: ValidatorFn | ValidatorObj | Array<(ValidatorFn | ValidatorObj)>) {
    let listValidator: ValidatorFn[] = [];
    let validatorNames: string[] = [];

    if (Array.isArray(validators)) {
      validators.forEach((item: ValidatorFn) => {
        const { functions, names } = this.handleValidator(item);
        listValidator = [...listValidator, ...functions];
        validatorNames = [...validatorNames, ...names];
      });
    } else {
      const { functions, names } = this.handleValidator(validators);
      listValidator = [...listValidator, ...functions];
      validatorNames = [...validatorNames, ...names];
    }

    if (!formControl['validatorChange']) {
      formControl['validatorChange'] = new Subject();
    }

    formControl.setValidators(listValidator);
    formControl['validatorNames'] = validatorNames;
    formControl['validatorChange'].next(validatorNames);
  }

  private static handleValidator(validator: ValidatorFn | ValidatorObj) {
    const functions: ValidatorFn[] = [];
    const names: string[] = [];

    if (typeof validator === 'function') {
      functions.push(validator as ValidatorFn);
    } else {
      Object.keys(validator).forEach((key: string) => {
        names.push(key);
        functions.push(validator[key]);
      });
    }

    return { functions, names };
  }

}
