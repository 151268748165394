import {BehaviorSubject} from 'rxjs';
import {PCacheable} from 'ts-cacheable';

import {Injectable} from '@angular/core';
import {
    Order,
    OrderStatus,
    Resource,
    Debt,
    MessageTemplate,
    Report,
    OrderItemDetail,
    OrderItem, FilmSize, FilmType, FilmOption,
    Comment
} from 'app/core/objects/models';
import {ApiService} from 'app/core/service';
import {OrderDto} from 'app/core/objects/dto';
import {History, FilmItem, FilmName} from 'app/core/objects/models';
import * as commonConstants from 'app/core/constant/common-constant';


@Injectable({
    providedIn: 'root'
})
export class OrdersService {

    public orderList: BehaviorSubject<Resource<Order>> = new BehaviorSubject(<Resource<Order>>{});
    public orderByUserList: BehaviorSubject<Resource<Order>>[] = [];

    constructor(private api: ApiService) {
    }

    public getListOfOrder(list: BehaviorSubject<Resource<Order>>, query, first, rows = 10, order?, orderBy?, fetchSuccess?) {
        let params = {
            ...query,
            page: (Math.trunc(first / rows)).toString(),
            limit: rows,
        };

        if (order && orderBy) {
            params = {...params, order, orderBy};
        }
        this.api.post<Resource<Order>>(ApiService.Api.getOrderList, {
            ...params,
        }).then(res => {
            list.next(res);
            if (fetchSuccess) {
                fetchSuccess();
            }
        });
    }

    @PCacheable({
        maxCacheCount: 3
    })
    getFilmSizes(params?) {
        return this.api.get<Resource<FilmSize>>(ApiService.Api.filmSizeList, {
            params
        });
    }

    @PCacheable({})
    getAllFilmSizes() {
        return this.api.get<Resource<FilmSize>>(ApiService.Api.filmSizeAll, {});
    }

    @PCacheable({
        maxCacheCount: 3
    })
    getFilmTypes(params?) {
        return this.api.get<Resource<FilmType>>(ApiService.Api.filmTypeList, {
            params
        });
    }

    @PCacheable({})
    getAllFilmTypes() {
        return this.api.get<Resource<FilmType>>(ApiService.Api.filmTypeAll, {});
    }

    @PCacheable({
        maxCacheCount: 3
    })
    getFilmOptions(params?) {
        return this.api.get<Resource<FilmOption>>(ApiService.Api.filmOptionList, {
            params
        });
    }

    @PCacheable({})
    getAllFilmOptions() {
        return this.api.get<Resource<FilmOption>>(ApiService.Api.filmOptionAll, {});
    }

    @PCacheable({
        maxCacheCount: 3
    })
    getMetaPrices(params?) {
        return this.api.get<Resource<any>>(ApiService.Api.metaPriceList, {
            params
        });
    }

    @PCacheable({
        maxCacheCount: 10,
    })
    getFilmItems(params?) {
        return this.api.get<Resource<FilmItem>>(ApiService.Api.filmItemList, {
            params
        });
    }

    @PCacheable({})
    getAllFilmItems() {
        return this.api.get<Resource<FilmItem>>(ApiService.Api.filmItemAll, {});
    }

    calculateOrder(order: OrderDto, user_id) {
        return this.api.post<any>(ApiService.Api.calculateOrder, {...order, user_id, orderId: order.id});
    }

    createOrder(order: OrderDto) {
        return this.api.post<Order>(ApiService.Api.order, order);
    }

    getOrder(id: string) {
        return this.api.get<Order>(ApiService.Api.order + id);
    }

    getOrderDetails(id: string) {
        return this.api.get<Order>(ApiService.Api.orderDetails, {params: {id}});
    }

    deleteOrder(id: string) {
        return this.api.delete<Order>(ApiService.Api.order + id);
    }


    updateOrder(id: string, query: { [name: string]: any } = {}) {
        return this.api.put<Order>(ApiService.Api.order + id, query);
    }


    doneItem(id: string, done: boolean) {
        return this.api.put<OrderItem>(ApiService.Api.orderItemDone + id, {done});
    }

    getOrderTypeSummary(query: { [name: string]: string[] | string } = {}) {
        return this.api.post<any>(ApiService.Api.orderTypeSummary, {
            ...query
        });
    }

    searchOrders(query, rows, first) {
        return this.api.post<Resource<Order>>(ApiService.Api.advancedSearchOrders, {
            ...query,
            limit: rows,
            page: (Math.trunc(first / rows)).toString(),
        });
    }

    updateManyStatus(ids: string[], status: OrderStatus) {
        return this.api.put<any>(ApiService.Api.orderStatus, {
            ids,
            status,
        });
    }

    updateAllStatus(fromStatus: OrderStatus, toStatus: OrderStatus, orderNumbers: string[] = []) {
        return this.api.put<any>(ApiService.Api.allOrderStatus, {
            orderNumbers,
            fromStatus,
            toStatus
        });
    }

    validateStatus(status: OrderStatus, orderNumbers: string[] = []) {
        return this.api.post<any>(ApiService.Api.validateOrderStatus, {
            orderNumbers,
            status,
        });
    }

    getOrderHistory(orderId: string) {
        return this.api.get<Resource<History>>(ApiService.Api.orderHistoryList, {
            params: {
                order_id: orderId,
            }
        });
    }

    createDebt(debt: Debt) {
        return this.api.post<Debt>(ApiService.Api.debt, debt);
    }

    updateDebt(debt: Debt) {
        return this.api.put<Debt>(ApiService.Api.debt + `/${debt.id}`, debt);
    }

    removeDebt(debt: Debt) {
        return this.api.delete<Debt>(ApiService.Api.debt + `/${debt.id}`);
    }

    addOrderItemDetails(details: OrderItemDetail[], next) {
        return this.api.post<OrderItemDetail[]>(ApiService.Api.orderItemDetailList, {details, next});
    }

    updateOrderItemDetails(details: OrderItemDetail[], next?) {
        return this.api.put<OrderItemDetail[]>(ApiService.Api.orderItemDetailList, {details});
    }

    deleteOrderItemDetail(id: string) {
        return this.api.delete<OrderItemDetail>(ApiService.Api.orderItemDetail + id);
    }

    getFolderList(order: Order) {
        return this.api.get<any>(ApiService.Api.folderList + order.id);
    }

    shareFolder(fileId: string, order: Order) {
        return this.api.put<any>(ApiService.Api.folder + order.id, {fileId});
    }

    undoShareFolder(folderMetaId: string) {
        return this.api.put<any>(ApiService.Api.undoFolder, {id: folderMetaId});
    }

    sendEmail(email) {
        return this.api.post<any>(ApiService.Api.email, email);
    }

    quickShare(order: Order, message) {
        return this.api.post<any>(ApiService.Api.quickShare + order.id, {message});
    }

    @PCacheable({
        maxCacheCount: 3
    })
    getSharingEmailTemplate(code: string) {
        return this.api.get<MessageTemplate>(ApiService.Api.template + code);
    }

    getDailyReports() {
        return this.api.get<Resource<Report>>(ApiService.Api.dailyReportList);
    }

    createDailyReport(report: Report) {
        return this.api.post<Report>(ApiService.Api.report, {
            from_time: report.from_time,
            to_time: report.to_time,
            film_income: report.film_income,
            coffee_income: report.coffee_income,
            note: report.note
        });
    }

    deleteDailyReport(report: Report) {
        return this.api.delete<any>(ApiService.Api.report + report.id);
    }

    @PCacheable({
        maxCacheCount: 10
    })
    getFilmNameList(film_type_id: string) {
        return this.api.get<Resource<FilmName>>(ApiService.Api.allFilmNameList, {
            params: {
                film_type_id
            }
        });
    }

    @PCacheable({
        maxCacheCount: 3
    })
    getDaysRetrieve() {
        return this.api.get<{ id: string, code: string, value: number }>(ApiService.Api.configuration, {
            params: {
                code: commonConstants.CONFIG_CODES.DAYS_RETRIEVE
            }
        });
    }

    @PCacheable({
        maxCacheCount: 3
    })
    getFilmNames(first, limit, name = '') {
        return this.api.get<Resource<FilmName>>(ApiService.Api.filmNameList, {
            params: {
                page: (Math.trunc(first / limit)).toString(),
                limit,
                name
            }
        });
    }

    getReportDetail(id) {
        return this.api.get<Report>(ApiService.Api.report + id);
    }

    async payOrderDebt(order: Order, debt: Debt, dialogService, sharedService, userService, spinnerName?) {
        const module = await import('../../core/modals/debt-logging-modal/debt-logging-modal.component');
        dialogService.open(module.DebtLoggingModalComponent, {
            header: 'Pay this debt?',
            baseZIndex: 1003,
            data: {
                order,
                debt,
                isPaying: true,
                okButton: {
                    label: 'Pay',
                    icon: 'fa-hand-holding-usd'
                },
                indelible: true
            },
        }).onClose.subscribe(result => {
            if (result) {
                sharedService.toggleLoading(true, spinnerName);
                this.updateDebt(result.data).then(() => {
                    sharedService.addMessage('Paid the debt');
                    const index = (order.debts || []).findIndex(i => i.id === result.data.id);
                    if (index !== -1) {
                        order.debts[index] = {...debt, ...result.data, updater: userService.user, updatedAt: new Date()}
                    }
                    sharedService.toggleLoading(false, spinnerName);
                })
            }
        });
    }

    getPackageDetail(id) {
        return this.api.get<any>(ApiService.Api.postageDetail, {params: {id}});
    }

    clearOrderCache(id) {
        return this.api.post<string>(ApiService.Api.clearOrderCache, {id})
    }

    resendPrintingOrderEmail(id) {
        return this.api.post<string>(ApiService.Api.resendPrintEmail, {id})
    }


    getOrderConfig() {
        return this.api.get<any>(ApiService.Api.orderConfig);
    }

    updateOrderConfig(orderConfig) {
        return this.api.put<any>(ApiService.Api.orderConfig, {
            orderConfig
        });
    }

    addComment(orderId: string, comment: Comment) {
        return this.api.post<Comment>(ApiService.Api.orderComment, {
            id: orderId,
            ...comment
        });
    }

    updateComment(comment: Comment) {
        return this.api.put<Comment>(ApiService.Api.orderComment, {
            ...comment
        });
    }

    markSeenComment(commentId: string) {
        return this.api.put<Comment>(ApiService.Api.orderCommentSeen, {
            id: commentId
        });
    }


    deleteComment(commentId: string) {
        return this.api.delete<any>(ApiService.Api.adminOrderComment, {
           params: {
               id: commentId
           }
        });
    }
}
