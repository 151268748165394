import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any[], queries: any[] = []): any[] {
    if (!value || !_.isArray(value) || !value.length) {
      return [];
    }

    let result = value;
    queries.forEach(q => {
      if (!q.field || !q.value) {
        return;
      }
      result = result.filter(i => i[q.field] === q.value);
    })
    return result;
  }
}
