import {Pipe, PipeTransform} from '@angular/core';
import {uniq} from 'lodash';

@Pipe({
  name: 'urlConvert'
})
export class UrlConvertPipe implements PipeTransform {

  transform(value: string): string {
    const regex = /((http(s)?(\:\/\/))?(www\.)?([\a-zA-Z0-9-_\.\/])*(\.[a-zA-Z]{2,3}\/?))([\a-zA-Z0-9-_\/?=&#+])*(?!(.*a>)|(\'|\"))/g;
    let match = value.match(regex);
    if (!match || !match.length) {
      return value;
    }
    match = uniq(match);
    let final = value;
    match.forEach(url => {
      final = final.replace(new RegExp(url, 'g'), '<a href="' + url + '" target="_BLANK">' + url + '</a>')
    });
    return final;
  }

}
