import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

export class RequiredObject {
  formControl: UntypedFormControl;
  symbol = '*';
  keys: string[] = ['required'];
  name: string;
}


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[required-for]'
})
export class RequiredForDirective implements OnInit, OnDestroy {
  subscription: Subscription;
  // tslint:disable-next-line:no-input-rename
  @Input('required-for') requiredFor: RequiredObject;

  constructor(private element: ElementRef) {
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngOnInit() {
    if (this.requiredFor.formControl['validatorChange'] && this.requiredFor.formControl['validatorNames']) {
      this.requiredFor = {
        ... new RequiredObject(),
        ... this.requiredFor
      };

      this.subscription = this.requiredFor.formControl['validatorChange'].subscribe((validatorNames: string[]) => {
        this.handleValidatorChange(validatorNames);
      });

      this.handleValidatorChange(this.requiredFor.formControl['validatorNames']);
    }
  }

  handleValidatorChange(validatorNames: string[]) {
    if (!validatorNames) {
      return;
    }

    const that = this;
    const required = validatorNames.some((name: string) => {
      return that.requiredFor.keys.includes(name);
    });

    if (required) {
      this.element.nativeElement.innerText = this.requiredFor.symbol;
    } else {
      this.element.nativeElement.innerText = '';
    }
  }
}
