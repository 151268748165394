import {Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserService} from '../../home/user/user.service';
import * as commonConstants from '../constant/common-constant';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appHasRole]'
})
export class RoleDiscriminationDirective implements OnInit, OnDestroy {
  private _resource: string;
  private _object: any;

  subscription: Subscription;

  @Input() set appHasRole(value: string) {
    this._resource = value;
  }
  @Input() set appHasRoleObject(object: any) {
    this._object = object;
  }



  constructor(private element: ElementRef,
              private viewContainerRef: ViewContainerRef,
              private templateRef: TemplateRef<any>,
              private userService: UserService) {
  }

  ngOnInit() {
    if (this._object) {
      this.toggleElementDisplay(this._object);
    } else {

      this.subscription = this.userService.userSubject.subscribe(user => {
        this.toggleElementDisplay(user);
      });
    }
  }

  toggleElementDisplay(element) {
    this.viewContainerRef.clear();

    if ((((element || {})._embedded || {}).resources || []).indexOf(this._resource) !== -1) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
