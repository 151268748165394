<p-autoComplete class="user-input" [(ngModel)]="value" [suggestions]="filteredUsers" (completeMethod)="search($event)"
                [multiple]="multiple" delay="1000" [minLength]="3" appendTo="body" [overlayOptions]="{baseZIndex: 1005}" [field]="displayedCustomField ?? 'full_name'"
             [disabled]="disabled"
                placeholder="Search users..." [showEmptyMessage]="true"
                (onSelect)="emitSelect($event)" (onUnselect)="emitUnselect($event)" (onClear)="emitClear()">
  <ng-template let-user pTemplate="item">
    <div class="ui-helper-clearfix">
      <ng-template [ngIf]="displayedCustomField">
        <div><b>{{user[displayedCustomField]}}</b></div>
      </ng-template>

      <ng-template [ngIf]="!displayedCustomField">
        <div><b>{{user.full_name}}</b></div>
        <div>{{user.email}}</div>
        <div>{{user.phone}}</div>
      </ng-template>
    </div>
  </ng-template>
</p-autoComplete>
