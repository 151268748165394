import {Component, OnInit, ContentChild, Input} from '@angular/core';
import {FormArrayName} from '@angular/forms';
import {BaseFormFieldComponent} from '../base-form-field/base-form-field.component';

@Component({
  selector: 'app-form-array-field',
  templateUrl: '../base-form-field/base-form-field.component.html',
  styleUrls: ['../base-form-field/base-form-field.component.less']
})
export class FormArrayFieldComponent extends BaseFormFieldComponent implements OnInit {

  @ContentChild(FormArrayName) controlName: FormArrayName;
  @Input() label: string;
  @Input() showLabel = true;
  @Input() styleClass: string;
  @Input() containerClass: string;

  specialCases: { [name: string]: (label: any, error: any) => string; } = {
    min_products: (label, error) => {
      return error.errorMessage;
    }
  };

  get errorMessage(): string {
    return this.getErrorMessage(this.controlName);
  }

}
