import { Utils } from 'app/core/helper/Utils';


export class PartialClosing {
  current_step: number;
  steps: TotalDetail[];
  public static generateZeroTotal() {
    const zeroTotal = <StepDetail>{
      orders: 0,
      quantity: 0,
      price: 0,
    };
    return <TotalDetail>{
      detail: {
        film_total: { ...zeroTotal },
        paid_debt_total: { ...zeroTotal },
        normal_print_total: { ...zeroTotal },
        packed_print_total: { ...zeroTotal },
      },
      total: 0
    }
  }
  public static calculateStepsTotal(steps: TotalDetail[]) {
    const stepsTotal = PartialClosing.generateZeroTotal();
    if (!Utils.isUndefinedOrEmpty(steps)) {
      steps.forEach(step => {
        Object.keys(step.detail).forEach(key => {
          stepsTotal.detail[key].orders += step.detail[key].orders;
          stepsTotal.detail[key].quantity += step.detail[key].quantity;
          stepsTotal.detail[key].price += step.detail[key].price;
        });
        stepsTotal.total += step.total;
      });
    }

    return stepsTotal;
  }
}

export interface TotalDetail {
  detail: {
    film_total: StepDetail;
    paid_debt_total: StepDetail;
    normal_print_total: StepDetail;
    packed_print_total: StepDetail;
  }
  total: number;
  closed_at?: number;
  closed_by?: string;

}

export interface StepDetail {
  orders: number;
  quantity: number;
  price: number;
}
