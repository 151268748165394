import {Directive, ElementRef, Input, ViewContainerRef, AfterViewInit} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[autofocus]'
})
export class AutofocusDirective implements AfterViewInit {
  private _autofocus;

  constructor(private el: ElementRef, private _view: ViewContainerRef) {
  }

  ngAfterViewInit() {
    // const componentView = (<any>this._view)._data.componentView;
    // const component = componentView ? componentView.component : null;
    // if (this._autofocus || typeof this._autofocus === 'undefined') {
    //     if (component && component.focus) {
    //         setTimeout(() => {component.focus()});
    //     } else if (this.el.nativeElement) {
    //         setTimeout(() => {this.el.nativeElement.focus()});
    //     }
    // }
    this.el.nativeElement.focus();
  }

  @Input() set autofocus(condition: boolean) {
    // tslint:disable-next-line:triple-equals
    this._autofocus = condition != false;
  }
}
