import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router, ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import * as _ from 'lodash';

import {SharedService} from './shared.service';
import {ApiConstants} from 'app/core/constant/api-constants';
import * as commonConstants from '../constant/common-constant';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  static Api = ApiConstants;

  static changeParamKeysToValues(currentUrlBuilder: string, params: any): string {
    let changingCurrentUrl: string = currentUrlBuilder;
    const paramsInUrl = currentUrlBuilder.match(/\/:\w+/gi);
    if (paramsInUrl) {
      for (const urlParameter of paramsInUrl) {
        const paramKey = urlParameter.replace('/:', '');
        changingCurrentUrl = changingCurrentUrl.replace(urlParameter, `/${params[paramKey] || ''}`);
        delete params[paramKey];
      }
    }
    return changingCurrentUrl;
  }

  constructor(private sharedService: SharedService, private router: Router,
              private route: ActivatedRoute, private messageService: MessageService, private http: HttpClient) {
  }


  private getToken() {
    return localStorage.getItem(commonConstants.STORE_CODES.TOKEN) || '';
  }

  private genericMethod<T>(targetMethod: 'get' | 'post' | 'put' | 'delete', url: string, body, options = <{
    headers?: HttpHeaders | {
      [header: string]: string | string[],
    },
    params?: HttpParams | {
      [param: string]: string | string[],
    }
  }>{}): Promise<T> {
    if (!options.headers) {
      options.headers = {Authorization: this.getToken()};
    }
    options.headers['accept-language'] = 'en';

    let observable: any;
    let finalUrl;
    if ((body?.params || body || options?.params) && url.indexOf('/:') !== -1) {
      finalUrl = ApiService.changeParamKeysToValues(url, body?.params || body || options.params);
    } else {
      finalUrl = url;
    }

    if (options.params) {
      options.params = _.pickBy(options.params, v => v !== undefined)
    }

    if (targetMethod === 'get' || targetMethod === 'delete') {
      observable = (this.http[targetMethod] as any)(`${ApiConstants.serverBaseUrl}${finalUrl}`, options);
    } else {
      observable = this.http[targetMethod].apply(this.http, [`${ApiConstants.serverBaseUrl}${finalUrl}`, body, options]);
    }

    return observable.toPromise()
      .then(response => response['data'] as T)
      .catch(error => {
        console.error(error);
        if (!error.error) {
          error.error = {
            message: `Something's wrong`,
            statusCode: 500,
          }
        }
        let errMsg: string;
        if (error.error.errors) {
          errMsg = error.error.errors.map(e => e.message).join('\n');
        } else {
          errMsg = error.error.message;
        }
        this.sharedService.addMessage(errMsg, 'error');
        this.sharedService.toggleLoading(false);

        switch (error.error.statusCode) {
          // case 404:
          //   this.router.navigate(['../404'], { relativeTo: this.route });
          //   break;
          case 403:
            this.router.navigate(['/error/403']);
            break;
          case 401:
            this.router.navigate(['/auth/login']);
            break;
        }
        throw error.error;
      });
  }


  public get<T>(url: string, options = <{
    headers?: HttpHeaders | {
      [header: string]: string | string[],
    },
    params?: HttpParams | {
      [param: string]: string | string[],
    }
  }>{}) {
    return this.genericMethod<T>('get', url, undefined, options);
  }

  public post<T>(url: string, body, options = <{
    headers?: HttpHeaders | {
      [header: string]: string | string[],
    },
    params?: HttpParams | {
      [param: string]: string | string[],
    }
  }>{}) {
    return this.genericMethod<T>('post', url, body, options);
  }

  public put<T>(url: string, body, options = <{
    headers?: HttpHeaders | {
      [header: string]: string | string[],
    },
    params?: HttpParams | {
      [param: string]: string | string[],
    }
  }>{}) {
    return this.genericMethod<T>('put', url, body, options);
  }

  public delete<T>(url: string, options = <{
    headers?: HttpHeaders | {
      [header: string]: string | string[],
    },
    params?: HttpParams | {
      [param: string]: string | string[],
    }
  }>{}) {
    return this.genericMethod<T>('delete', url, options);
  }


}
