import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import * as commonConstant from '../constant/common-constant';

@Pipe({
  name: 'timestampToDate'
})
export class TimestampToDatePipe implements PipeTransform {

  transform(value: number, timeFormat?: string): any {
    const date = new Date(value);

    return value ? moment.unix(value).format(timeFormat || commonConstant.dateTimeFormat) : '';
  }

}
