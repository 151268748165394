import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from '../helper';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: number, format: 'long' | 'short' = 'short'): string {
    if (Utils.isUndefinedOrNull(value)) {
      return '';
    }
    if (value === 0) {
      return '0';
    }

    if (format === 'short') {
      return `${(value / 1000).toLocaleString()}K`
    }
    return `${value.toLocaleString()}\u20AB`;
  }

}
