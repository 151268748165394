import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User, Resource} from 'app/core/objects/models';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';

import {ApiService} from '../../core/service/api.service';
import {UserDto} from 'app/core/objects/dto/UserDto';
import {FormControlDecorator, CustomValidators} from 'app/common/form';
import {Validators} from '@angular/forms';
import {PCacheable} from 'ts-cacheable';
import {SharedService} from 'app/core/service/shared.service';
import * as commonConstants from '../../core/constant/common-constant';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  public user: User;
  public userSubject = new BehaviorSubject<User>(null);
  public breadcrumbStack = <MenuItem[]>[];
  public breadcrumbParams = {};

  static generateCommonUserRules(isNameEditable, user) {
    return {
      id: FormControlDecorator.create(user.id),
      full_name: FormControlDecorator.create({
        value: user.full_name,
        disabled: !isNameEditable
      }, [{required: Validators.required},
        Validators.minLength(5),
        Validators.maxLength(30),
        Validators.pattern(/^[a-zA-Z_àáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ/.\s]{1,15}(?: [a-zA-Z_àáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ/.\s]{1,15})+$/u)]),
      email: FormControlDecorator.create(user.email,
        [{required: Validators.required}, Validators.email]),
      phone: FormControlDecorator.create(user.phone,
        [{required: Validators.required}, CustomValidators.phone()]),
    }
  }

  constructor(private api: ApiService, private sharedService: SharedService) {
  }

  storeToken(token: string) {
    localStorage.setItem(commonConstants.STORE_CODES.TOKEN, token);
  }

  public guestLogin(saveToken = true) {
    return this.api.post<{ token: string, locations: any[] }>(ApiService.Api.guestLoginUrl, {})
      .then(res => {
        this.sharedService.locationSubject.next(res.locations);
        if (saveToken) {
          this.storeToken(res.token);
        }
      });
  }


  public userLogin(email, password, location, reCaptcha_v3_token) {
    return this.api.post<{ token: string }>(ApiService.Api.userLoginUrl,
      {
        email,
        password,
        location,
        reCaptcha_v3_token
      })
      .then(res => {
        this.storeToken(res.token);
        return Promise.resolve();
      });
  }


  public logout() {
    return this.api.post<{ auth: any }>(ApiService.Api.userLogoutUrl, {});
  }

  public getSelfInfo(advanced = false) {
    return this.api.get<User>(advanced ? ApiService.Api.userProfileAdvanced : ApiService.Api.userProfileUrl);
  }

  @PCacheable({
    maxCacheCount: 10
  })
  public searchUsersBasic(params) {
    return this.api.get<Resource<User>>(ApiService.Api.searchUser, {
      params
    });
  }

  public searchUsersAdvanced(params) {
    return this.api.get<Resource<User>>(ApiService.Api.advancedUserList, {
      params
    });
  }

  public getUserById(id, advanced = false) {

    return this.api.get<any>((advanced ? ApiService.Api.advancedUser : ApiService.Api.user) + id);
  }

  public getUserMetaById(id) {
    return this.api.get<any>(ApiService.Api.userMeta, {
      params: {id}
    });
  }

  public searchUsers(queryFields: { field: string, compareOperator: string, value: string[] }[]) {
    return this.api.post<Resource<User>>(ApiService.Api.advancedSearchUsers, {
      fields: queryFields
    });
  }

  public searchUsersByKeyWord(keyWord: string) {
    return this.searchUsers([
      {field: 'email', compareOperator: 'like', value: [keyWord]},
      {field: 'phone', compareOperator: 'like', value: [keyWord]},
      {field: 'full_name', compareOperator: 'like', value: [keyWord]},
    ]);
  }

  public createOrUpdateUser(user: UserDto, isCreate: boolean, advanced = false) {
    let url = advanced ? ApiService.Api.advancedUser : ApiService.Api.user;
    if (!isCreate) {
      url += user.id;
    }
    return this.api[isCreate ? 'post' : 'put']<User>(url, user);
  }

  public updateAdvancedProfile(user: UserDto) {
    return this.api.put<User>(ApiService.Api.userProfileAdvanced, user);
  }

  public onCreateOrUpdateUserModal(dialogService: DialogService, user: User,
                                   options: {
                                     indelible?: boolean, is_advanced?: boolean,
                                     userTypes?: any[], nameEditable?: boolean, params?: any
                                   }
                                     = {
                                     indelible: true,
                                     is_advanced: false,
                                     userTypes: [],
                                     nameEditable: false,
                                     params: {}
                                   }): Promise<{ ref: any, response: { action: string, data: User } }> {
    const {indelible = true, is_advanced = false, userTypes = [], params = {}, nameEditable = false} = options
    const isNew = !user;
    const isNameEditable = this.user._embedded.resources.includes('update-user-name') || nameEditable;

    let rules: any = {
      ...(UserService.generateCommonUserRules(isNameEditable, {
        id: isNew ? null : user.id,
        full_name: isNew ? '' : user.full_name,
        email: isNew ? params.email || '' : user.email,
        phone: isNew ? params.phone || '' : user.phone
      })),
      location: FormControlDecorator.create(this.user.logged_in_location),
    };
    let elementSettings: any = {
      id: {hidden: true},
      location: {hidden: true},
      email: {keyFilter: 'email'},
      phone: {keyFilter: 'num'},
    };

    if (is_advanced) {
      rules = {
        ...rules,
        roles: FormControlDecorator.create<string[]>(user ? (user.roles || [])
            : [(userTypes.find(i => i.name === 'Customer') || {code: ''}).code],
          [{required: Validators.required}]),
        locations: FormControlDecorator.create<string[]>(user ? user.locations : []),
        discount: FormControlDecorator.create<number>(user ? user.discount : 0,
          [Validators.min(0), Validators.max(100)]),
        is_active: FormControlDecorator.create<boolean>(user ? !!user.is_active : true),
      }
      elementSettings = {
        ...elementSettings,
        roles: {
          type: 'multiselect',
          optionDisabled: 'disabled',
          selections: userTypes.map(i => ({label: i.name, value: i.code, disabled: i.code === 'CUST'}))
        },
        locations: {
          type: 'multiselect',
          selections: (this.sharedService.locationSubject.value || [])
            .map(i => ({label: i.name, value: i.code}))
        },
        discount: {keyFilter: 'num'},
        is_active: {type: 'checkbox', label: 'Active'},
      }
    }

    return new Promise<{ ref: DynamicDialogRef, response: { action: string, data: User } }>(async (resolve, reject) => {
      const module = await import('../../core/modals/crud-modal/crud-modal.component');
      const ref = dialogService.open(module.CrudModalComponent, {
        header: `${!isNew ? 'Edit' : 'Create'} User`,
        baseZIndex: 1004,
        data: {
          model: user,
          isNew,
          indelible,
          rules,
          url: ApiService.Api.user,
          options: elementSettings,
          urlByActionMap: is_advanced ? {'put': ApiService.Api.advancedUser} : {},
          hardcoreDelete: true
        }
      });

      ref.onClose.subscribe({
        next: (response) => {
          resolve({ref, response});
        }, error: (err) => {
          reject({ref, err});
        }
      });

    });
  }

  public sendVerificationEmail(email: string) {
    return this.api.get(ApiService.Api.verifyEmail, {
      params: {
        email
      }
    })
  }

  public forgotPassword(email) {
    return this.api.post(ApiService.Api.forgotPassword, {
      email
    })
  }

  public changeLocation(location) {
    return this.api.put<{ token }>(ApiService.Api.userLocation, {location}).then(res => {
      if (res) {
        this.storeToken(res.token);
      }
    });
  }

  getCouponsByUserId(userId, first, rows) {
    return this.api.get<Resource<any>>(ApiService.Api.coupons, {
      params: {
        page: (Math.trunc(first / rows)).toString(),
        limit: rows,
        userId
      }
    });
  }

  getCouponTransactionsByUserId(userId, first, rows) {
    return this.api.get<Resource<any>>(ApiService.Api.couponTransactions, {
      params: {
        page: (Math.trunc(first / rows)).toString(),
        limit: rows,
        userId
      }
    });
  }

  getCouponDetail(id) {
    return this.api.get<any>(ApiService.Api.coupon + id);
  }

  deleteUser(userId) {
    return this.api.delete<any>(ApiService.Api.user + userId);
  }

  getOnlineUsers() {
    return this.api.get<any>(ApiService.Api.onlineUsers);
  }

  notifyOnlineUsers(message, userId?) {
    return this.api.post<any>(ApiService.Api.onlineUsers, {
      message,
      userId
    });
  }

  getUserTasks(userId, first, rows, status?, type?, location?) {
    const params: any = {
      page: (Math.trunc(first / rows)).toString(),
      limit: rows,
      status,
      type,
    }

    if (type && type === 'STAFF_ASSIGNMENT') {
      params.location = location || this.user.logged_in_location;
    } else {
      params.userId = userId;
    }

    return this.api.get<Resource<any>>(ApiService.Api.userTasks, {
      params
    });
  }

  updateUserTask(task) {
    return this.api.put<any>(ApiService.Api.userTask, task);
  }

  async openUserDetailModal({dialogService, user, userId, callback}: any) {
    const module = await import('../../core/modals/wrapper-modal/wrapper-modal.component');
    dialogService.open(module.WrapperModalComponent,
      {
        baseZIndex: 1004,
        width: '80%',
        header: 'User Detail',
        data: {
          mode: 'user-detail',
          userId,
          user
        },
      }).onClose.subscribe(response => {
      if (response) {
        if (callback) {
          callback();
        }
      }
    });
  }

}
