<div class="menu-container {{styleClass ?? ''}}">
    <p-menu #menu appendTo="body" [baseZIndex]="1005" [popup]="true" [model]="items" (onShow)="handleIcon($event)"
            (onHide)="handleIcon($event)"></p-menu>


    <p-button #showBtn [rounded]="true" [outlined]="true" severity="secondary"
              [ngClass]="buttonClass ?? 'label dropdown'"
              icon="{{icon || 'icon icon-color-white icon-menu'}}"
              [iconPos]="iconPos"
              [label]="label"
              [raised]="raised"
              (click)="menu.toggle($event);$event.preventDefault();$event.stopPropagation();"></p-button>

</div>
