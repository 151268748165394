export interface Debt {
    id: string;
    amount: number;
    cause: string;
    paid: boolean;
    paidAt: Date;
    order_id: string;
    user_id: string;
    location?: string;
    creator: any;
    updater: any;
    createdAt: Date;
    updatedAt: Date;
    paymentMethod?: string;
}
