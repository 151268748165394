import {Pipe, PipeTransform} from '@angular/core';
import * as commonConstants from '../constant/common-constant';
import {OrderItem} from '../objects/models';

@Pipe({
  name: 'productName',
  pure: false
})
export class ProductNamePipe implements PipeTransform {

  transform(value: OrderItem, format: 'long' | 'short' = 'long'): any {

    const filmItem = value.film_item;

    let filmTypeName = format === 'long' ? filmItem.film_type.name : filmItem.film_type.abbreviation;
    if (filmItem.film_size.code === 'custom' && value.order_itemmetas && value.order_itemmetas.length) {
      const customOption = value.order_itemmetas.find(i => i.film_option.code === 'custom');
      filmTypeName = (customOption || {}).originalValue || filmTypeName;
    }

    if (format === 'long') {
      return `${filmItem.film_size.name} ${filmTypeName}`;
    }
    return `${filmItem.film_size.abbreviation} ${filmTypeName}`;
  }

}
