import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  OrderNumberPipe,
  PaymentMethodPipe, PricePipe,
  ProductNamePipe,
  TimestampToDatePipe,
  YesNoPipe,
  UrlConvertPipe,
  FilterPipe
} from './pipes';
import {
  RequiredForDirective,
  AutofocusDirective,
  DynamicDisableDirective,
  AfterValueChangedDirective,
  PaymentMethodDirective,
  RoleDiscriminationDirective
} from './directives';


@NgModule({
  declarations: [
    ProductNamePipe,
    TimestampToDatePipe,
    OrderNumberPipe,
    YesNoPipe,
    PricePipe,
    PaymentMethodPipe,
    RequiredForDirective,
    AutofocusDirective,
    DynamicDisableDirective,
    AfterValueChangedDirective,
    PaymentMethodDirective,
    RoleDiscriminationDirective,
    FilterPipe,
    UrlConvertPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ProductNamePipe,
    TimestampToDatePipe,
    OrderNumberPipe,
    YesNoPipe,
    PricePipe,
    PaymentMethodPipe,
    FilterPipe,
    RequiredForDirective,
    AutofocusDirective,
    DynamicDisableDirective,
    AfterValueChangedDirective,
    PaymentMethodDirective,
    RoleDiscriminationDirective,
    UrlConvertPipe
  ]
})
export class CoreModule {
}
