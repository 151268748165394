import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {User} from '../../core/objects/models';
import {SharedService} from '../../core/service';
import {UserService} from '../../home/user/user.service';
import {NotificationService} from '../../core/service/notification.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-online-staff',
    templateUrl: './online-staff.component.html',
    styleUrls: ['./online-staff.component.less']
})
export class OnlineStaffComponent implements OnInit, OnDestroy {

    @Input() showHeader = true;
    @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
    onlineStaff: User[] = [];
    staffLoading = false;

    message = '';

    socketSub: Subscription;

    constructor(public sharedService: SharedService, public userService: UserService,
                public notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.socketSub = this.notificationService.socketSubject.subscribe(socket => {
            socket.on('onlineUsers', (onlineUsers: any[]) => {
                this.onlineStaff = onlineUsers;
                console.log({onlineUsers});
                this.sharedService.numberOfOnlineStaff = this.onlineStaff.length;
            });
        });

    }

    ngOnDestroy() {
        if (this.socketSub) {
            this.socketSub.unsubscribe();
        }
    }

    notifyOnlineUsers() {
        if (this.message) {
            this.notificationService.sendMessage(this.message, []);
            this.message = '';
        }

    }

    onRowSelect(event, selected: boolean) {
        const {data} = event;
        if (this.sharedService.conversationSubject.value.some(i => i.id === data.id)) {
            this.closePanel.emit();
            return;
        }
        const newValue = this.sharedService.conversationSubject.value;
        newValue.push({
            id: data.id,
            firstPerson: {
                name: data.fullName,
                avatar: data.avatar
            },
            secondPerson: {
                name: data.fullName,
                avatar: data.avatar
            },
            messages: []
        });
        this.sharedService.conversationSubject.next(newValue);
        this.closePanel.emit();
    }

}
