import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {BaseInputControl} from '../form/base-input-control/base-input-control';
import {User} from '../../core/objects/models';
import {UserService} from '../../home/user/user.service';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {CommonHelpers} from '../../core/helper';

@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserInputComponent),
      multi: true,
    },
  ]
})
export class UserInputComponent extends BaseInputControl<User[] | User> implements OnInit {

  @Input() multiple = true;
  @Input() displayedCustomField = null;
  @Input() resultList = null;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onUnselect: EventEmitter<any> = new EventEmitter();
  @Output() onClear: EventEmitter<any> = new EventEmitter();
  filteredUsers: any[] = [];

  constructor(public userService: UserService) {
    super();
  }

  ngOnInit(): void {
  }

  search({query}) {
    if (!this.resultList) {
      this.userService.searchUsersByKeyWord(query).then(response => {
        this.filteredUsers = response.items;
      });
    } else {
      this.filteredUsers = this.resultList.filter(i => {
        const sanitisedName = CommonHelpers.changeAlias(i.fullName).toLowerCase();
        const sanitisedInput = CommonHelpers.changeAlias(query).toLowerCase();
        return sanitisedName.includes(sanitisedInput);
      });
    }
  }

  emitSelect(event) {
    this.onSelect.emit(event);
  }

  emitUnselect(event) {
    this.onUnselect.emit(event);
  }

  emitClear() {
    this.onClear.emit();
  }
}
