export interface FilmName {
  id: string;
  name: string;
  brand: string;
  iso: number;
  origin: string;
  film_size: {
    id: string;
    name: string;
  },
  film_type: {
    id: string;
    name: string;
    code: string
  }
}
