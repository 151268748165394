import * as _ from 'lodash';

export class Utils {
  public static isUndefinedOrEmpty(value) {
    return _.isUndefined(value) || _.isNull(value) || _.isEmpty(value);
  }

  public static isUndefinedOrNull(value) {
    return _.isUndefined(value) || _.isNull(value);
  }

  public static isDefinedAndNotNull(value) {
    return !_.isUndefined(value) && !_.isNull(value);
  }

  public static isEmpty(value) {
    return _.isEmpty(value);
  }

  public static isUndefined(value) {
    return _.isUndefined(value);
  }

  public static differentItems<T>(newList: T[], oldList: T[]) {
    const isAddNew = newList.length > oldList.length;
    let biggerList: T[];
    let smallerList: T[];
    if (isAddNew) {
      biggerList = newList;
      smallerList = oldList;
    } else {
      biggerList = oldList;
      smallerList = newList;
    }
    const differentItems = biggerList.map(i => i).filter(i => !smallerList.map(x => x).includes(i));
    return {isAddNew, list: differentItems};
  }

  public static isNumberic(value: string) {
    return /^(\d|-)+$/.test(value);
  }

  public static diff(obj1, obj2) {
    if (obj1 && !obj2) {
      return obj1;
    }

    if (!obj1 && obj2) {
      return obj2;
    }

    if (Array.isArray(obj1) && Array.isArray(obj2) && obj1.length !== obj2.length) {
      return {
        before: '', after: `${obj1.length < obj2.length ? 'Add' :
          'Remove'} ${Math.abs(obj1.length - obj2.length)} item(s)`
      }
    }
    const differentObjects = _.omitBy(obj1, (x, i) => Utils.isEqual(x, obj2[i]));
    Object.keys(differentObjects).forEach(key => {
      if (typeof differentObjects[key] !== 'object') {
        differentObjects[key] = {before: obj1[key], after: obj2[key]};
      } else {
        differentObjects[key] = this.diff(obj1[key], obj2[key]);
      }
    })
    return differentObjects;
  }

  static isEqual(obj1: any, obj2: any, pickFields?: string[], omitFields?: string[]): boolean {
    let clone2;
    let clone1;
    clone1 = _.omitBy(obj1, x => this.omitFunc(x));
    clone2 = _.omitBy(obj2, x => this.omitFunc(x));
    if (!pickFields && !omitFields) {
      return _.isEqual(clone1, clone2)
    } else {
      if (pickFields) {
        clone1 = _.pick(clone1, pickFields);
        clone2 = _.pick(clone2, pickFields);
        return _.isEqual(clone2, clone1);
      }
      if (omitFields) {
        clone1 = _.omit(clone1, pickFields);
        clone2 = _.omit(clone2, pickFields);
        return _.isEqual(clone2, clone1);
      }
    }
  }

  static omitFunc(x: any) {
    return _.isNull(x) || _.isUndefined(x) || x === '' || (Array.isArray(x) && x.length === 0);
  }


  static assignByKeys(destinationObject: any, sourceObject: any, exclude = []) {
    return _.assign(destinationObject, _.pick(sourceObject, _.keys(destinationObject).filter(key => !exclude.includes(key))));
  }

  static stringFormat(source: string, args: any[]): string {
    if (!source || !args) {
      return source;
    }
    return source.replace(/{(\d+)}/g, (match, number) => {
      return (typeof args[number] !== 'undefined' && args[number] !== null) ? args[number] : match;
    });
  }

  static copyObjectKeepReference(source, destination, excepts = []) {
    if (!source) {
      source = {};
    }
    Object.keys(destination).filter(key => !excepts.includes(key))
      .forEach(attr => {
        source[attr] = destination[attr];
      })
  }
}
