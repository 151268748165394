import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PortletBodyComponent} from './portlet-body.component';
import {PortletHeaderComponent} from './portlet-header.component';
import {PortletFooterComponent} from './portlet-footer.component';

export interface PortletOptions {
  test?: any;
}

@Component({
  selector: 'app-portlet',
  templateUrl: './portlet.component.html',
  styleUrls: ['./portlet.component.less'],
  exportAs: 'appPortlet'
})
export class PortletComponent implements OnInit, AfterViewInit {
  @Input() options: PortletOptions;
  @Input() class: string;

  @ViewChild('refPortlet', {static: true}) refPortlet: ElementRef;

  @ViewChild(PortletHeaderComponent, {static: false}) header: PortletHeaderComponent;
  @ViewChild(PortletBodyComponent, {static: false}) body: PortletBodyComponent;
  @ViewChild(PortletFooterComponent, {static: false}) footer: PortletFooterComponent;

  ngOnInit() {
  }

  ngAfterViewInit() {
  }
}
