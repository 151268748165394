import {User} from './User';

export interface Comment {
    id?: string;
    orderId?: string;
    user?: User;
    userId?: string;
    content: string;
    edited?: boolean;
    public: boolean;
    seen?: boolean;
    isDeleted?: boolean;
    createdAt?: Date;
    _embedded?: any;
}
