import {MessageService} from 'primeng/api';
import {AppComponent} from './app.component';
import {AppRoutingModule} from 'app/app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SocketIoModule} from 'ngx-socket-io';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';

import {CommonsModule} from 'app/common/common.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  RouterGuardService,
  ApiInterceptor,
} from 'app/core/service';
import {UserService} from 'app/home/user/user.service';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SocketIoModule,
    RecaptchaV3Module
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    RouterGuardService,
    MessageService,
    UserService,
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcTmHYaAAAAAJWL-NyZuZrIeREmIVXQtePxYxTa'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
