<app-portlet [class]="'mb-2'">
  <app-portlet-header *ngIf="showHeader" [title]="'Online Staff'" [class]="" icon="fas fa-users">
  </app-portlet-header>
  <app-portlet-body>
    <div class="online-staff">
      <p-table [value]="onlineStaff" styleClass="p-datatable-striped" responsiveLayout="scroll"
               [rows]="30"
               selectionMode="single"
               (onRowSelect)="onRowSelect($event, true)"
               (onRowUnselect)="onRowSelect($event, false)"
               [totalRecords]="onlineStaff.length" [loading]="staffLoading"
      >
        <ng-template pTemplate="body" let-staff>
          <tr [pSelectableRow]="staff" class="">
            <td class="p-1" colspan="2">
              <p-avatar [icon]="staff?.avatar ? '' : 'fas fa-user'" [image]="staff?.avatar" size="small"
                        shape="circle" class="center-element"></p-avatar>
            </td>
            <td class="p-2" colspan="6">{{staff.fullName ?? ("id: " + staff.id)}}</td>
<!--            <td class="p-1" colspan="3">-->
<!--              <p-tag *ngFor="let role of staff.roles" [value]="role[0]" class="mr-1"-->
<!--                     severity="{{role === 'Administrator' ? 'danger' : (role === 'Counter' ? 'info' : (role === 'Technical' ? 'warning': 'success'))}}"-->
<!--                     pTooltip="{{role}}"></p-tag>-->
<!--            </td>-->
            <td class="p-1" colspan="1">
              {{staff.logged_in_location}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </app-portlet-body>
  <app-portlet-footer>
    <div class="llab-portlet__foot-wrapper">

      <div class="p-inputgroup flex-grow-1">
        <input type="text" [(ngModel)]="message" [ngModelOptions]="{ standalone: true }" pInputText
               placeholder="Send message to all online staff"
               (keyup.enter)="$event.preventDefault();$event.stopPropagation();notifyOnlineUsers();">
        <button type="button" pButton icon="fas fa-paper-plane"
                class="p-button-primary"
                (click)="$event.preventDefault();$event.stopPropagation();notifyOnlineUsers()"></button>
      </div>
    </div>
  </app-portlet-footer>
</app-portlet>
