import {Socket, SocketIoConfig} from 'ngx-socket-io';
import * as _ from 'lodash';
import {environment} from '../../../environments/environment';
import * as commonConstants from '../constant/common-constant';

export class WebSocket extends Socket {

  constructor(user?) {
    const token = localStorage.getItem(commonConstants.STORE_CODES.TOKEN);
    const config: SocketIoConfig = {
      url: `${environment.host}`,
      options: {
        transports: ['websocket'],
        query: {
          token,
          user: user ? JSON.stringify(_.pick(user, ['id', 'full_name', 'email', 'avatar'])) : null
        },
      }
    };
    super(config);

    this.on('connect', () => {
      console.log('Connected to notification server!');
    });

    this.on('disconnect', () => {
      console.log('Disconnected to notification server!');
    });

    this.on('reconnect', () => {
      console.log('Reconnected to notification server!');
    });


    this.on('connect_failed', () => {
      console.log('Cannot connect to notification server!', 'error');
    })
  }

  public destroy() {
    this.disconnect(true);
    this.removeAllListeners();
  }

}
