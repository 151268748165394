import { FilmSize } from './FilmSize';
import { FilmOption } from './FilmOption';

export interface FilmItem {
  id: string;
  film_size_id: string;
  film_type_id: string;
  film_type: {
    id: string;
    name: string;
    code: string;
    order: number;
    abbreviation: string;
  };
  film_size: FilmSize;

  film_options: FilmOption[];
  meta_price: { id: string; price: number };

  quantity: number;

}
