import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import 'hammerjs';
import {FormControlFieldComponent, FormArrayFieldComponent} from './form';
import {MenuComponent} from './menu/menu.component';
import {FlexTagListComponent} from './flex-tag-list/flex-tag-list.component';
import {MenuModule} from 'primeng/menu';
import {TooltipModule} from 'primeng/tooltip';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {CoreModule} from '../core/core.module';
import {PortletComponent} from './portlet/portlet.component';
import {PortletBodyComponent} from './portlet/portlet-body.component';
import {PortletHeaderComponent} from './portlet/portlet-header.component';
import {PortletFooterComponent} from './portlet/portlet-footer.component';
import {SubheaderComponent} from './subheader/subheader.component';
import {RippleModule} from 'primeng/ripple';
import {OnlineStaffComponent} from './online-staff/online-staff.component';
import {TableModule} from 'primeng/table';
import {AvatarModule} from 'primeng/avatar';
import {TagModule} from 'primeng/tag';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {UserInputModule} from './user-input/user-input.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MenuModule,
    TooltipModule,
    InputTextModule,
    ButtonModule,
    CoreModule,
    RippleModule,
    TableModule,
    AvatarModule,
    TagModule,
    CheckboxModule,
    DropdownModule,
    UserInputModule,
  ],
  exports: [
    MenuComponent,
    FormControlFieldComponent,
    FormArrayFieldComponent,
    FlexTagListComponent,
    PortletComponent,
    PortletFooterComponent,
    PortletBodyComponent,
    PortletHeaderComponent,
    SubheaderComponent,
    OnlineStaffComponent
  ],
  declarations: [
    MenuComponent,
    FormControlFieldComponent,
    FormArrayFieldComponent,
    FlexTagListComponent,
    PortletComponent,
    PortletFooterComponent,
    PortletBodyComponent,
    PortletHeaderComponent,
    SubheaderComponent,
    OnlineStaffComponent
  ],
})
export class CommonsModule {
}
