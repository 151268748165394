import {UserDto} from '../dto/UserDto';

export interface User extends UserDto {
  uid: string;
  roles: string[];
  // stores: string[];
  // defaultStore: string;

  full_name: string;
  logged_in_location: string;
  description: string;
  avatar: string;
  gender: string;
  address: string;
  dob: Date;
  phone_code: string;
  country: string;
  nationality: string;
  state: string;
  city: string;
  zip_code: string;
  note: string;
  merchant: string;
  locations: string[];
  is_active: boolean;
  is_verified: boolean;
  createdAt: Date;
  discount: number;
  admin: boolean;
  _embedded: {
    resources: string[];
  }
  meta?: {
    memberPoints: number;
    rewardPoints: number;
    numberOfTasks: number;
  }
}
