import {Directive, ElementRef, Input, OnChanges} from '@angular/core';
import {PaymentMethodPipe} from '../pipes';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[paymentMethod]'
})
export class PaymentMethodDirective implements OnChanges {
  @Input() paymentMethod: string;
  @Input() spanClass = '';
  @Input() display = '';

  constructor(private element: ElementRef) {
  }

  ngOnChanges() {
    const pipe = new PaymentMethodPipe();
    this.element.nativeElement.innerHTML = pipe.transform(this.paymentMethod, this.spanClass, this.display);
  }

}
