import { StockStatus } from '../../enum';

export interface StockRequest {
  stocks: string[];
  creator: string;
  createdAt: number;
  updatedAt: number;
  status: string;
  note: string;
}
