import {OrderItem, History, Comment} from 'app/core/objects/models/index';
import {Debt} from 'app/core/objects/models/Debt';
import {FilmTypeDto, OptionDto, OrderDto} from '../dto';
import {OrderType} from '../../enum';


export interface Order extends OrderDto {


    createdAt: Date;
    creator: any;
    expiryDate: Date;

    order_number: string;

    status: OrderStatus;
    type: string;
    updatedAt: Date;

    debts: Debt[];


    paidAt: Date;

    order_items: OrderItem[];

    discountInfo: any
    discount_code: string;
    discount_price: number;
    subTotal: number;
    total_price: number;
    total_rolls: number;
    coupon: {
        use: number,
        discount: number;
        available: any[]
    }
    coupons: any[];
    comments: Comment[];
    tags: any[];

    postage: any;

    shippingFee?: number;

    hasSpecialRequirement: boolean;
    hasUnseenComment: boolean;
    folderMeta: any[];
    userMeta?: any;
    location: string;
    source: { id: string, code: string }

    activities?: History[];

    _embedded: {
        resources: string[];
        statusStyle: any;
        retrieval: any;
    }
}

export enum OrderStatus {
    new = 'new',
    processing = 'processing',
    transiting = 'transiting',
    awaitingReturn = 'awaiting_return',
    done = 'done',
    suspended = 'suspended',
    deleted = 'deleted',
    taken = 'taken',
    expired = 'expired',
    destroyed = 'destroyed'
}

export interface FilmType extends FilmTypeDto {
    film_type: FilmTypeDto;
    service: OptionDto;
    is_border: boolean;
    is_high_res: boolean;
    is_push: number;
    common_item: FilmType;
    film_size: { id: string, name: string };
    options: Option[];
    note: string;
    name: string;
    code: string;
    order: number;
    orderType: OrderType;
    _embedded: {
        style: any;
    }
}

export interface Option extends OptionDto {
    meta_price?: OptionDto;
    slug?: string;
    order?: string;
    price?: number;
    _embedded: {
        style: any;
    }
}

