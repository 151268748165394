import {Component, OnInit, Input} from '@angular/core';
import * as _ from 'lodash';

import * as commonConstants from 'app/core/constant/common-constant';

@Component({
  selector: 'app-flex-tag-list',
  templateUrl: './flex-tag-list.component.html',
  styleUrls: ['./flex-tag-list.component.less']
})
export class FlexTagListComponent implements OnInit {

  @Input() option: {
    from: number,
    to: number,
    shortTextField: string,
    fullTextField: string,
    idPath: string,
    stylePath?: string,
    transformText: boolean,
    extraContent?: {
      contentField: string,
      values: { [code: string]: boolean },
      comparedField: string,
    }
  };
  @Input() list: any[];

  shortList: any[];

  commonConstants = commonConstants;
  get = _.get;
  includes = _.includes;

  constructor() {
  }

  ngOnInit() {
    this.option = {
      from: 11,
      to: 12,
      shortTextField: 'label',
      fullTextField: 'label',
      stylePath: '_embedded?.style',
      transformText: true,
      ...this.option
    }
    this.shortList = _.uniqBy(this.list, this.option.idPath);
  }

  onHover(control, item) {
    if (!this.option.transformText) {
      return;
    }
    control.classList.toggle(`col-md-${this.option.to}`);
    const extraText = this.option.extraContent
    && _.includes(this.option.extraContent.values, _.get(item, this.option.extraContent.comparedField))
      ?
      ` (${_.get(item, this.option.extraContent.contentField)})` : '';

    if (control.classList.contains(`col-md-${this.option.to}`)) {
      control.children[0].textContent = `${_.get(item, this.option.fullTextField)} ${extraText}`;
    } else {
      control.children[0].textContent =
        `${_.get(item, this.option.shortTextField)}${extraText}`;
    }


  }

}
