<div class="d-flex flex-wrap llab-subheader mt-2 mb-2" [ngClass]="styleClass">
  <div class="mr-auto d-flex align-items-center justify-content-start llab-subheader__left" *ngIf="title">
    <button pButton pRipple type="button" icon="fas fa-chevron-left" class="p-button-rounded p-button-text"
            label="{{title}}" (click)="clickTitle($event)"></button>
  </div>
  <div class="d-flex align-items-center justify-content-end llab-subheader__right">
    <ng-content select="[right]"></ng-content>
  </div>
</div>

