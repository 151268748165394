import {ValidatorFn, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as commonConstants from '../../core/constant/common-constant';
import {FilmTypeDto} from '../../core/objects/dto';
import {Utils} from '../../core/helper';

export class CustomValidators {

  static phone(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } | null => {
      return !commonConstants.phoneNumberFormatRegex.test(control.value) ? {'phone': {value: control.value}} : null;
    };
  }

  static minProducts(min: number): ValidatorFn {
    return (control: UntypedFormGroup): { [key: string]: any } | null => {
      const items = control.value as FilmTypeDto[];
      if (items.length > 0 && items.some(i => i.quantity >= min)) {
        return null;
      }

      return {
        'min_products': {
          value: control.value,
          errorMessage: Utils.stringFormat(commonConstants.errorMessages.min_products, [min])
        }
      };
    };
  }

  static requiredGroup(): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } | null => {
      const controls = group.controls;
      if (Object.keys(controls).some(key => controls[key].errors && controls[key].errors.required)) {
        return {
          'required_group': {
            value: group.value,
            errorMessage: commonConstants.errorMessages.required_group,
          }
        };
      }

      return null;
    };
  }


  static requiredIf(dependantName: string, satistifiedValue: any, operator: string): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } | null => {
      if (!control.parent) {
        return null;
      }

      const parent = control.parent as UntypedFormGroup;

      const comparedControl = parent.get(dependantName) as UntypedFormControl;
      if (!comparedControl) {
        return null;
      }
      switch (operator) {
        case '=':
          if (+comparedControl.value === +satistifiedValue) {
            return Validators.required(control)
          }
          break;
        case '!=':
          if (+comparedControl.value !== +satistifiedValue) {
            return Validators.required(control)
          }
          break;
        case '>':
          if (+comparedControl.value > +satistifiedValue) {
            return Validators.required(control)
          }
          break;
        case '>=':
          if (+comparedControl.value >= +satistifiedValue) {
            return Validators.required(control)
          }
          break;
        case '<':
          if (+comparedControl.value < +satistifiedValue) {
            return Validators.required(control)
          }
          break;
        case '<=':
          if (+comparedControl.value <= +satistifiedValue) {
            return Validators.required(control)
          }
          break;
      }

      return null;
    };
  }

  static minArray(min: number): ValidatorFn {
    return (control: UntypedFormGroup): { [key: string]: any } | null => {
      const items = control.value as any[];
      if (items.length >= min) {
        return null;
      }

      return {
        'minArray': {
          value: control.value,
          errorMessage: Utils.stringFormat(commonConstants.errorMessages.minArray, [min])
        }
      };
    };
  }

}
