
import { FilmName } from './FilmName';
export interface OrderItemDetail {
  id: string;
  folder_id: number;
  order_item_id: string;
  quantity: number;
  film_name: FilmName;
  film_name_id: string;
  note: string;
  extra: string;
  error: boolean;
  index?: number;

}
