import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';

import * as commonConstants from 'app/core/constant/common-constant';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {

  @Input() items: MenuItem[];
  @Input() label: string;
  @Input() showIcon = true;
  @Input() icon: string;
  @Input() iconPos: any = 'right';
  @Input() buttonClass: string;
  @Input() styleClass: string;
  @Input() flexLabel = false;
  @Input() raised = false;

  constructor(public router: Router, protected route: ActivatedRoute) { }

  ngOnInit() {
    this.items = _.orderBy(this.items, ['order']);
    if (this.flexLabel) {
      this.items.forEach(item => {
        const temp = item.command;
        item.command = (event) => {
          temp(event);
          this.label = item.label;
        }
      });
    }

  }

  handleIcon(event) {
  }

}
