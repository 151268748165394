export class ApiConstants {
  // TODO: do something here
  static serverBaseUrl = '';

  // Auth
  static guestLoginUrl = '/v1/private/auth/login-anonymous';
  static userLoginUrl = '/v1/private/auth/login';
  static userProfileUrl = '/v1/admin/user-profile';
  static userProfileAdvanced = '/v1/admin/user-profile-advanced';
  static userLogoutUrl = '/v1/private/auth/logout';
  static verifyEmail = '/v1/g/user/resend_verification_email';
  static forgotPassword = '/v1/g/user/forgot-password';

  // Category
  static filmSizeList = '/v1/admin/film-sizes';
  static filmSizeAll = '/v1/admin/film-sizes/all';
  static filmTypeList = '/v1/admin/film-types';
  static filmTypeAll = '/v1/admin/film-types/all';
  static filmOptionList = '/v1/admin/film-options';
  static filmOptionAll = '/v1/admin/film-options/all';
  static filmItemList = '/v1/admin/film-items';
  static filmItemAll = '/v1/admin/film-items/all';
  static filmSize = '/v1/admin/film-size';
  static filmType = '/v1/admin/film-type';
  static filmOption = '/v1/admin/film-option';
  static filmItem = '/v1/admin/film-item';

  // Order
  static calculateOrder = '/v1/admin/order-calculate/';
  static order = '/v1/admin/order/';
  static orderDetails = '/v1/admin/order/:id/details';
  static getOrderList = '/v1/admin/orders';
  static orderTypeSummary = '/v1/admin/order-type-summary';
  static topCustomers = '/v1/admin/order-top-customers';
  static orderStatus = '/v1/admin/order-status';
  static allOrderStatus = '/v1/admin/order-status/all';
  static validateOrderStatus = '/v1/admin/order-status/validate';
  static countOrderRolls = '/v1/admin/order-rolls-counting';
  static clearOrderCache = '/v1/admin/order/:id/clear-cache';
  static orderComment = '/v1/p/order/:id/comment';
  static adminOrderComment = '/v1/admin/order/:id/comment';
  static orderCommentSeen = '/v1/admin/order/:id/seen-comment';

  // Order Item
  static orderItem = '/v1/admin/order-item/';
  static orderItemDone = '/v1/admin/order-item-done/';

  // Meta Price
  static metaPriceList = '/v1/admin/meta-prices';
  static metaPrice = '/v1/admin/meta-price';

  // Order History
  static orderHistoryList = '/v1/admin/order-histories';


  // User
  static searchUser = '/v1/admin/users';
  static user = '/v1/admin/user/';
  static userMeta = '/v1/admin/user-meta/:id';
  static advancedUserList = '/v1/admin/advanced-users';
  static advancedUser = '/v1/admin/advanced-user/';
  static userLocation = '/v1/admin/user-location';
  static onlineUsers = '/v1/admin/online-users';

  // User type
  static userTypeList = '/v1/admin/user-types';

  // Search
  static advancedSearchOrders = '/v1/admin/advanced-search/orders';
  static advancedSearchUsers = '/v1/admin/advanced-search/users';

  // Debt
  static debtList = '/v1/admin/debts';
  static debt = '/v1/admin/debt';

  // Film Details
  static orderItemDetailList = '/v1/admin/order-item-details';
  static orderItemDetail = '/v1/admin/order-item-detail/';
  static orderItemDetailTopFilmName = '/v1/admin/order-item-detail/top-film-names';

  // Email
  static folderList = '/v1/admin/email/folders/';
  static folder = '/v1/admin/email/folder/';
  static undoFolder = '/v1/admin/email/folder/:id/undo';
  static authorization = '/v1/admin/email/authorization/';
  static email = '/v1/admin/email/';
  static quickShare = '/v1/admin/email/quick-share/';
  static resendPrintEmail = '/v1/admin/email/print-resend/:id';

  // Message Template
  static template = '/v1/admin/message-template/';
  static templateList = '/v1/admin/message-templates/';

  // Report
  static dailyReportList = '/v1/admin/daily-reports/';
  static reportList = '/v1/admin/reports';
  static report = '/v1/admin/report/';

  static statistic = '/v1/admin/report-stats';
  static summary = '/v1/admin/report-summary';

  // FilmType
  static filmNameList = '/v1/admin/film-names';
  static allFilmNameList = '/v1/admin/film-names/all';
  static filmName = '/v1/admin/film-name';

  // Configuration
  static settings = '/v1/admin/configurations/settings';
  static configuration = '/v1/admin/configuration';
  static orderConfig = '/v1/admin/configurations/order-config';

  // Ads
  static ads = '/v1/admin/ads';

  // Notification
  static notifications = '/v1/admin/notifications';
  static notification = '/v1/admin/notification';
  static pushNotification = '/v1/admin/notifications/push';

  // Discount
  static discounts = '/v1/admin/discounts';
  static discount = '/v1/admin/discount';

  // Reward
  static rewards = '/v1/admin/rewards';
  static reward = '/v1/admin/reward';

  // Shipping
  static shippingRates = '/v1/private/shipping-rates';
  static shippingRatesAdmin = '/v1/admin/shipping-rates';
  static shippingRate = '/v1/admin/shipping-rate';
  static pickupOrders = '/v1/private/pickup-orders';
  static pickupOrder = '/v1/private/pickup-order/:id';
  static pickupOrderUrgePicking = '/v1/private/pickup-order/:id/urge-picking';
  static convertPickupOrder = '/v1/private/pickup-order/:id/convert';
  static pickupOrderPayment = '/v1/private/pickup-order/:id/payment';
  static pickupOrderStatus = '/v1/private/pickup-order-status/:id';
  static pickupOrderItem = '/v1/private/pickup-order-item/';
  static getCities = '/v1/admin/shipping-rate/area/cities';
  static allAreas = '/v1/admin/shipping-rate/areas';
  static getDistricts = '/v1/admin/shipping-rate/area/city/:id/districts';
  static getWards = '/v1/admin/shipping-rate/area/district/:id/wards';
  static getSelections = '/v1/p/area/selection';
  static urgePicking = '/v1/private/pickup-order/:id/urge-picking';

  // FAQ
  static faqList = '/v1/admin/configurations/faq';

  // Postage
  static postage = '/v1/admin/postage';
  static postageDetail = '/v1/p/post/:id';

  // Version
  static versions = '/v1/admin/versions';
  static version = '/v1/admin/version/:id';

  // Coupon
  static coupons = '/v1/admin/coupons';
  static coupon = '/v1/admin/coupon/';
  static couponTransactions = '/v1/p/coupon-transactions';

  // User task
  static userTasks = '/v1/admin/user-tasks';
  static userTask = '/v1/admin/user-task/:id';

}
