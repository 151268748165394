import {FilmItem, FilmOption, FilmSize} from 'app/core/objects/models/index';
import {OrderItemDetail} from './OrderItemDetail';

export interface OrderItem {
    id: string;
    film_item_id: string;
    quantity: number;
    done: boolean;
    order_itemmetas: {
        film_option_id: string,
        value: number,
        originalValue: string,
        film_option?: FilmOption
    }[];
    film_item: FilmItem;
    order_item_details: OrderItemDetail[];
    service: FilmOption;
    film_size_id: string;
    film_type_id: string;
    film_type: {
        id: string;
        name: string;
        code: string;
        order: number;
    };
    film_size: FilmSize;
    sub_total: number;
    totalPrice: number;
}
