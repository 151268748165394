import {
  Directive,
  Host,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChange,
  SkipSelf,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlContainer, AbstractControl, UntypedFormGroup } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControlName][dynamicDisable]',
})
export class DynamicDisableDirective implements OnInit, OnChanges {
  @Input() formControlName: string;
  @Input() dynamicDisable: boolean;
  private ctrl: AbstractControl;
  constructor( @Optional() @Host() @SkipSelf() private parent: ControlContainer, private ref: ChangeDetectorRef) {

  }

  ngOnInit() {
    if (this.parent && this.parent['form']) {
      this.ctrl = (<UntypedFormGroup>this.parent['form']).get(this.formControlName);
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (!this.ctrl) {
      this.ctrl = (<UntypedFormGroup>this.parent['form']).get(this.formControlName);
    }
    if (!this.ctrl) {
      return;
    }
    if (this.dynamicDisable) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }
    this.ref.markForCheck();
  }
}
