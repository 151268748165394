import {FilmSize, Order, OrderItem} from 'app/core/objects/models';
import * as commonConstants from '../constant/common-constant';
import * as moment from 'moment';
import {Utils} from 'app/core/helper/Utils';
import {ProductNamePipe} from 'app/core/pipes/productName.pipe';
import * as _ from 'lodash';

export class CommonHelpers {

  public static changeAlias(alias) {
    let str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
    str = str.replace(/ + /g, ' ');
    str = str.trim();
    return str;
  }

  public static convertToNormalText(telexText: string): string {
    // Define rules for converting Telex text to normal text
    const telexToNormalMap: { [key: string]: string } = {
      'á': 'as', 'à': 'af', 'ả': 'ar', 'ã': 'ax', 'ạ': 'aj',
    'â': 'aa',  'ấ': 'aas', 'ầ': 'aaf', 'ẩ': 'aar', 'ẫ': 'aax', 'ậ': 'aaj',
     'ă': 'aw', 'ắ': 'aws', 'ằ': 'awf', 'ẳ': 'awr', 'ẵ': 'awx', 'ặ': 'awj',
      'é': 'es', 'è': 'ef', 'ẻ': 'er', 'ẽ': 'ex', 'ẹ': 'ej',
      'ê': 'ee', 'ế': 'ees', 'ề': 'eef', 'ể': 'eer', 'ễ': 'eex', 'ệ': 'eej',
      'ó': 'os', 'ò': 'of', 'ỏ': 'or', 'õ': 'ox', 'ọ': 'oj',
      'ô': 'oo', 'ố': 'oos', 'ồ': 'oof', 'ổ': 'oor', 'ỗ': 'oox', 'ộ': 'ooj',
      'ớ': 'ows', 'ờ': 'owf', 'ở': 'owr', 'ỡ': 'owx', 'ợ': 'owj',
      'ú': 'us', 'ù': 'uf', 'ủ': 'ur', 'ũ': 'ux', 'ụ': 'uj',
      'ứ': 'uws', 'ừ': 'uwf', 'ử': 'uwr', 'ữ': 'uwx', 'ự': 'uwj',
      'í': 'is', 'ì': 'if', 'ỉ': 'ir', 'ĩ': 'ix', 'ị': 'ij',
      'đ': 'dd', 'ý': 'ys', 'ỳ': 'yf', 'ỷ': 'yr', 'ỹ': 'yx', 'ỵ': 'yj',
    };

    // Regular expression to match Telex patterns
    const telexRegex = new RegExp(Object.keys(telexToNormalMap).join('|'), 'g');

    let normalText = telexText.toLowerCase();
    // Replace Telex patterns with their corresponding normal text
    normalText = telexText.replace(telexRegex, match => telexToNormalMap[match]);

    return normalText;
  }


  public static buildDisplayTime(time: number, format: string) {
    return moment.unix(time).format(format).toString();
  }


  public static getCurrentTimestamp() {
    return +moment(Date.now(), 'x') / 1000;
  }

  public static buildEmailTemplate(order: Order, webViewLink: string, folderName: string, template: string, changeFolderInfo = true) {
    let result = template;
    result = result.replace(/{{CLIENT_NAME}}/g, (order.user || {}).full_name);
    result = result.replace(/{{ORDER_NUMBER}}/g, order.order_number.toString());
    result = result.replace(/{{ORDER_NOTE}}/g, `<strong style="color: rgb(230, 0, 0);">${order.public_note || ''}</strong>` || '');

    result = result.replace(/{{DAYS_RETRIEVE}}/g, moment(order.expiryDate).local(true).format(commonConstants.dateFormat));

    result = result.replace(/{{FILM_DETAILS}}/g, this.buildFilmDetailContent(order.order_items));

    if (changeFolderInfo) {
      result = result.replace(/{{FOLDER_LINK}}/g, webViewLink || '#');

      result = result.replace(/{{FOLDER_NAME}}/g, folderName || 'Không khả dụng/Not available');
    }

    return result;

  }

  private static buildFilmDetailContent(orderItems: OrderItem[]): string {
    const productNamePipe = new ProductNamePipe();
    const title = '<b>CHI TIẾT/DETAIL:</b><br/>';
    const details = orderItems.map(p =>
      `<br/><i><u>${p.quantity} cuộn/roll(s) ${productNamePipe.transform(p)}:</u></i><br/>${p.order_item_details
      && p.order_item_details.map(fd =>
        `- Roll: ${fd.film_name.name || ''},<strong style="color: rgb(230, 0, 0);"> Số ảnh/Quantity: ${fd.quantity ||
        ''} files${fd.note ? `, Ghi chú/Note: ${fd.note}` : ''}</strong>, Trạng thái/Status: ${p.done ? 'Đã hoàn tất/Done'
          : 'Đang chờ xử lý/Pending'}`
      ).join('<br/>')}<br/>`);

    return title + details.join('\n');
  }


  public static generateActionButtonsForOrder(order: Order, isSearchMode) {
    const buttons = [

      {
        icon: 'fas fa-info-circle', tooltip: `Add details`, actionRel: 'add-detail',
      },
      {
        icon: 'fas fa-info-circle', tooltip: `View details`, actionRel: 'view-detail',
      },
      {
        icon: 'fas fa-share-square', tooltip: 'Share', actionRel: 'share',
      },
      {
        icon: 'fas fa-arrow-alt-circle-right', tooltip: 'To Processing', actionRel: 'to-processing',
      },
      {
        icon: 'fas fa-check', tooltip: 'Done', actionRel: 'done',
      },
      {
        icon: 'fas fa-check', tooltip: 'Done', actionRel: 'done-temporarily',
      },
      {
        icon: 'fas fa-file-export', tooltip: 'Retrieve', actionRel: 'retrieve',
      },
      {
        icon: 'fas fa-paper-plane', tooltip: 'Resend Email', actionRel: 'resend-email',
      },
      {
        icon: 'fas fa-search', tooltip: 'Search', actionRel: 'search',
      },
      {
        icon: 'fas fa-pen', tooltip: 'Edit', actionRel: 'edit',
      },
      {
        icon: 'fas fa-hand-holding-usd', tooltip: 'Debit', actionRel: 'debit',
      },
      {
        icon: 'fas fa-undo', tooltip: 'Restore', actionRel: 'restore',
      },
      {
        icon: 'fas fa-pause', tooltip: 'Suspend', actionRel: 'suspend',
      },
      {
        icon: 'fas fa-trash', tooltip: 'Delete', actionRel: 'delete',
      },
      {
        icon: 'fas fa-truck', tooltip: 'To Transit', actionRel: 'transiting',
      },
      {
        icon: 'fas fa-mail-bulk', tooltip: 'Add Postage', actionRel: 'post',
      },
    ];
    const notSearchIncludedButtons = ['search'];
    return buttons.filter(i => (!isSearchMode || !notSearchIncludedButtons.includes(i.actionRel))
      && order._embedded.resources.includes(i.actionRel));

  }


  public static findFilmItemId(film_size_id: string, film_type_id: string, film_sizes?: FilmSize[], sharedService?) {
    film_sizes = film_sizes || (sharedService ? sharedService.filmSizeSubject.value : []);
    const film_item = ((film_sizes.find(i => i.id === film_size_id) || {film_types: []})
      .film_types.find(i => i.id === film_type_id) || {film_item: {}}).film_item;
    if (!film_item) {
      return null;
    }
    return film_item.id;
  }

  public static buildOrderItemsDto(order: Order, result, sharedService) {
    Utils.assignByKeys(result, order, ['order_items']);
    result.order_items = [];

    order.order_items.forEach((value, index) => {
      if (value.quantity === 0) {
        return;
      }
      const film_item_id = this.findFilmItemId(value.film_size.id, value.film_type.id, undefined, sharedService);
      const orderItem = <OrderItem>{
        quantity: value.quantity,
        film_item_id,
        order_itemmetas: [
          {
            film_option_id: value.service.id
          }
        ]
      };

      orderItem.order_itemmetas.push(...value['options']
        .map(i => ({film_option_id: i.id, value: i.value, originalValue: i.originalValue})));

      result.order_items.push(orderItem);

    });

  }

  public static async hardcoreDelete(dialogService, sharedService, successHandler, data = {
    message: `Please enter 'yes' to delete this user:`,
    header: 'Delete User',
    value: '',
    icon: 'fas fa-exclamation-triangle'
  }) {
    const module = await import('../modals/single-input-modal/single-input-modal.component');
    const ref = dialogService.open(module.SingleInputModalComponent, {
      data
    });
    ref.onClose.subscribe({
      next: async (response) => {
        if (response) {
          if (_.toLower(response.value) !== 'yes') {
            sharedService.addMessage('Wrong!', 'error');
            ref.close();
            await CommonHelpers.hardcoreDelete(dialogService, sharedService, data, successHandler);
          } else {
            successHandler();
          }
        }
      }
    })
  }

  public static convertToConfigurationModelFromForm(data, formData, excludedItems = []) {
    Object.keys(data).filter(i => !excludedItems.includes(i)).forEach(i => {
      if (data[i]) {
        data[i]['value'] = formData[i] ?? null;
      }
    });
    return data;
  }

}
