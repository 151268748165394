import {Directive, Input} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {Output, EventEmitter, ViewChildren} from '@angular/core';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BaseInputControl<T> implements ControlValueAccessor {
  private initialised = false;
  // tslint:disable-next-line:variable-name
  protected _value: T;
  @ViewChildren('input') vc;
  @Input() disabled = false;

  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  get value(): any {
    return this._value;
  }

  @Input()
  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
    }
  }

  // write the value to the input
  writeValue(value: any) {
    this._value = value;
  }

  // tslint:disable-next-line:variable-name
  protected _onChange = (_) => {}; // call it if your value changed..
  // tslint:disable-next-line:variable-name
  protected _onTouched = (_) => {}; // call it "on blur" ..

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: any) => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
