import {Pipe, PipeTransform} from '@angular/core';
import * as commonConstants from '../constant/common-constant';

@Pipe({
  name: 'orderNumber',
  pure: false
})
export class OrderNumberPipe implements PipeTransform {

  transform(value: string): any {

    if (!value) {
      return '';
    }
    const matchedArr = value.match(commonConstants.orderNumberPartsRegex);

    if (!matchedArr) {
      return value;
    }

    const isPrint = matchedArr[1];
    const day = matchedArr[2];
    const month = matchedArr[3];
    const order = matchedArr[4];

    return `${isPrint ? 'P_' : ''}${day}_${month}_${order}`;
  }
}
