import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.less']
})
export class SubheaderComponent implements OnInit {
  @Input() title: string;
  @Input() hideArrow = false;
  @Input() styleClass: string;
  @Output() titleClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  clickTitle(e) {
    e.preventDefault();
    e.stopPropagation();
    this.titleClick.emit();
  }

}
