import { Component, ContentChild, Input } from '@angular/core';
import { FormControlName } from '@angular/forms';

import * as commonConstants from '../../../core/constant/common-constant';
import { BaseFormFieldComponent } from '../base-form-field/base-form-field.component';
import {Utils} from '../../../core/helper';

@Component({
  selector: 'app-form-control-field',
  templateUrl: '../base-form-field/base-form-field.component.html',
  styleUrls: ['../base-form-field/base-form-field.component.less']
})
export class FormControlFieldComponent extends BaseFormFieldComponent {

  @ContentChild(FormControlName) controlName: FormControlName;
  @Input() label: string;
  @Input() showLabel = true;
  @Input() styleClass: string;
  @Input() containerClass: string;

  specialCases: { [name: string]: (label: any, error: any) => string; } = {
    minlength: (label, error) => {
      return Utils.stringFormat(commonConstants.errorMessages.minlength, [label, error.requiredLength]);
    },
    max: (label, error) => {
      return Utils.stringFormat(commonConstants.errorMessages.max, [error.max]);
    },
    min: (label, error) => {
      return Utils.stringFormat(commonConstants.errorMessages.min, [error.min]);
    },
    pattern: (label, error) => {
      return Utils.stringFormat(commonConstants.errorMessages.pattern, [label]);
    },
  };

  get errorMessage(): string {
    return this.getErrorMessage(this.controlName);
  }


}
