import {Component, OnInit, AfterViewInit, Input, ContentChild, Directive} from '@angular/core';
import {AbstractControlDirective, NgControl, AbstractControl} from '@angular/forms';

import * as commonConstants from 'app/core/constant/common-constant';
import {Utils} from 'app/core/helper';


@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BaseFormFieldComponent implements OnInit, AfterViewInit {

  abstract controlName: AbstractControlDirective;

  abstract get errorMessage(): string;

  abstract specialCases: { [name: string]: (label, error) => string }

  label: string;
  showLabel = true;
  styleClass: string;
  containerClass: string;
  validationMsg: string;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  get hidden(): boolean {
    return this.controlName && (this.controlName.pristine || !this.controlName.invalid);
  }

  getErrorMessage(controlName: { name: string | number, control: AbstractControl }): string {
    const errors = (controlName.control || {errors: null}).errors;
    if (!errors) {
      return '';
    }
    for (const violation of Object.keys(errors)) {
      if (errors[violation] && errors[violation].errorMessage) {
        return errors[violation].errorMessage;
      }

      if (this.specialCases[violation]) {
        return this.specialCases[violation](this.label || controlName.name, errors[violation]);
      }
      return Utils.stringFormat(commonConstants.errorMessages[violation], [this.label || controlName.name]) ||
        violation;
    }
  }

  handleSpecialCase(violation: string, controlName: { name: string, control: AbstractControl }, error: any) {
    if (violation === 'minlength') {
      return Utils.stringFormat(commonConstants.errorMessages[violation],
        [this.label || controlName.name, error.requiredLength]);
    }
    if (violation === 'max') {
      return Utils.stringFormat(commonConstants.errorMessages[violation],
        [error.max]);
    }
    if (violation === 'min') {
      return Utils.stringFormat(commonConstants.errorMessages[violation],
        [error.min]);
    }
  }

}
