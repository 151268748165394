import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {Utils} from 'app/core/helper/Utils';
import {BehaviorSubject} from 'rxjs';
import {DialogService} from 'primeng/dynamicdialog';
import {UserService} from '../../home/user/user.service';
import {FilmItem, FilmOption, FilmSize, FilmType, Resource, User} from '../objects/models';
import {PaymentMethod} from '../enum';
import * as commonConstants from 'app/core/constant/common-constant';
import {FormControlDecorator} from '../../common/form';
import {Validators} from '@angular/forms';
import * as _ from 'lodash';
import OrderConfig from '../objects/models/OrderConfig';
import {Conversation} from '../../common/messenger/messenger.component';


@Injectable({
    providedIn: 'root'
})
export class SharedService {
    branch: string;
    createNewOrder: (order, user?) => any;
    previewOrderBeforeCreate: (order, user) => any;

    sidebarDisplay: boolean;

    numberOfTasks: number;
    numberOfOnlineStaff = 0;
    notificationList = [];

    isGuestTokenReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    filmTypeSubject: BehaviorSubject<FilmType[]> = new BehaviorSubject<FilmType[]>(null);
    filmSizeSubject: BehaviorSubject<FilmSize[]> = new BehaviorSubject<FilmSize[]>(null);
    filmOptionSubject: BehaviorSubject<FilmOption[]> = new BehaviorSubject<FilmOption[]>(null);
    filmItemSubject: BehaviorSubject<FilmItem[]> = new BehaviorSubject<FilmItem[]>(null);

    locationSubject: BehaviorSubject<{ code: string, name: string, name_vi: string }[]>
        = new BehaviorSubject<{ code: string, name: string, name_vi: string }[]>(null);

    userTypeSubject: BehaviorSubject<Resource<{ id: string, name: string, code: string }>>
        = new BehaviorSubject<Resource<{ id: string, name: string, code: string }>>(null);

    conversationSubject: BehaviorSubject<Conversation[]> = new BehaviorSubject<Conversation[]>([]);

    currentLoggedInLocation: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    emailSharing: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    daysRetrieve: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    orderConfig: BehaviorSubject<OrderConfig> = new BehaviorSubject<OrderConfig>(null);

    currentOrderIdList: BehaviorSubject<string[]> = new BehaviorSubject([]);
    sharedData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    isSmallDevice = window.innerWidth < 768;

    public dialog: {
        display: boolean, header: string, content: string, contentStyleClass: string,
        buttons: { class: string, label: string, click: () => void }[]
    }
        = {display: false, header: '', content: '', contentStyleClass: '', buttons: []};

    constructor(private messageService: MessageService, private spinner: NgxSpinnerService,
                private recaptchaV3Service: ReCaptchaV3Service) {
    }


    public addMessage(message: string, severity: 'success' | 'error' | 'warn' | 'info' = 'success',
                      summary?: string, params?: string[], life = 3000) {
        if (params) {
            message = Utils.stringFormat(message, params);
        }

        this.messageService.add({
            severity,
            summary: severity === 'error' ? summary || 'Error' : summary,
            detail: message,
            life,
        });
    }

    public toggleLoading(value: boolean, name?: string) {
        if (value) {
            this.spinner.show(name);
        } else {
            this.spinner.hide(name);
        }
    }

    public async openQRScannerModal(userService: UserService, dialogService: DialogService): Promise<User> {
        const module = await import('../modals/qr-scanner-modal/qr-scanner-modal.component');
        return new Promise<User>((resolve, reject) => {
            dialogService.open(module.QRScannerModalComponent, {
                baseZIndex: 1004,
                data: {}
            }).onClose.subscribe((id) => {
                if (id) {
                    this.toggleLoading(true);
                    userService.getUserById(id).then(response => {
                        resolve(response);
                    }).catch(e => reject(e)).then(() => this.toggleLoading(false));
                }
            }, error => reject(error));
        });
    }

    validateRecaptcha(action: string) {
        return this.recaptchaV3Service.execute(action).toPromise();

    }

    async showPaymentMethodSelections(paid, paymentMethod, dialogService: DialogService) {
        paid = !!paid;
        paymentMethod = paymentMethod === PaymentMethod.UNPAID ? null : paymentMethod;

        const module = await import('../modals/crud-modal/crud-modal.component');
        return new Promise<any>((resolve, reject) => {
            dialogService.open(module.CrudModalComponent,
                {
                    header: 'Select Payment Method',
                    baseZIndex: 1004,
                    data: {
                        model: {
                            paid, paymentMethod
                        },
                        rules: {
                            paid: FormControlDecorator.create<boolean>(paid),
                            paymentMethod: FormControlDecorator.create<string>(paymentMethod, [Validators.required]),
                        },
                        options: {
                            paid: {type: 'checkbox'},
                            paymentMethod: {
                                type: 'dropdown',
                                selections: Object.keys(PaymentMethod).filter(i => i !== 'UNPAID').map(i => ({
                                    label: commonConstants.paymentMethodsNameMap[PaymentMethod[i]],
                                    value: PaymentMethod[i]
                                })),
                                dynamicDisable: (d) => {
                                    return !d.paid
                                }
                            },
                        }
                    }
                }).onClose.subscribe(response => {
                if (response && response.data) {
                    const {data: res} = response;
                    resolve(res);
                }

            }, error => reject(error));
        });
    }

    renameCurrentNodeOfBreadcrumbStack(userService: UserService, name, params?) {
        const currentNode = userService.breadcrumbStack[userService.breadcrumbStack.length - 1];
        currentNode.label = name;
        if (params) {
            userService.breadcrumbParams = {...userService.breadcrumbParams, ...params};
        }
        userService.breadcrumbStack = [...userService.breadcrumbStack];
    }

    print(printContents) {
        const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
         body p {
            white-space  : pre-line;
         }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
        );
        popupWin.document.close();
    }
}
