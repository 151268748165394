import {AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-portlet-header',
  styleUrls: ['portlet-header.component.less'],
  template: `
    <div class="llab-portlet__head-label" [hidden]="noTitle">
			<span class="llab-portlet__head-icon" #refIcon [hidden]="hideIcon">
				<ng-content *ngIf="!icon" select="[llabPortletIcon]"></ng-content>
				<i *ngIf="icon" [ngClass]="icon"></i>
			</span>
      <ng-content *ngIf="!title" select="[llabPortletTitle]"></ng-content>
      <h3 *ngIf="title" class="llab-portlet__head-title d-flex align-items-center">
        {{title}}<i *ngIf="tooltip" class="icon icon-info icon-md ml-2"
                    container="body"></i>
      </h3>
    </div>
    <div class="llab-portlet__head-toolbar" #refTools [hidden]="hideTools">
      <ng-content select="[llabPortletTools]"></ng-content>
    </div>`
})
export class PortletHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() class: string;
  @Input() title: string;
  @Input() tooltip: string;
  @Input() icon: string;
  @Input() noTitle: boolean;
  @Input() sticky: boolean;


  @Input() menu: { label: string, link: string }[];

  @HostBinding('class') classes = 'llab-portlet__head';

  @ViewChild('refIcon', {static: true}) refIcon: ElementRef;
  hideIcon: boolean;

  @ViewChild('refTools', {static: true}) refTools: ElementRef;
  hideTools: boolean;


  constructor(
    private el: ElementRef,
    private router: Router) {
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.classes += this.class ? ' ' + this.class : '';
    this.hideIcon = this.refIcon.nativeElement.children.length === 0;
    this.hideTools = this.refTools.nativeElement.children.length === 0;
  }


  navigate(link: string) {
    this.router.navigateByUrl(link);
  }

  ngOnDestroy(): void {
  }
}
