import {Injectable, OnDestroy} from '@angular/core';

import {SharedService} from './shared.service';
import {WebSocket} from 'app/core/service/web-socket';
import {MessageService} from 'primeng/api';
import {OrdersService} from 'app/home/orders/orders.service';
import {OrderStatus, User} from '../objects/models';
import {CommonHelpers} from 'app/core/helper';
import {DialogService} from 'primeng/dynamicdialog';
import {UserService} from '../../home/user/user.service';
import * as commonConstants from 'app/core/constant/common-constant';
import {Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';

interface Notification {
    message: string,
    type: 'NEW_ORDER' | 'REMIND_RETRIEVABLE_ORDERS' | 'REMIND_DEBT' | 'INTERNAL_MESSAGE' | string,
    title: string,
    data: any;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService implements OnDestroy {
    socket: WebSocket;
    socketSubject: BehaviorSubject<WebSocket> = new BehaviorSubject<WebSocket>(undefined);
    dialogService: DialogService;

    socketSub: Subscription;
    locationSub: Subscription;

    constructor(private messageService: MessageService, private ordersService: OrdersService,
                private sharedService: SharedService,
                public userService: UserService, private router: Router) {

    }

    initialize({dialogService}) {
        this.socketSub = this.userService.userSubject.subscribe(user => {
            if (user) {
                this.initializeSocket(user);
                this.socket.on('pushNotification', (notification: Notification) => {
                    console.log({notification})
                    this.displayMessage(notification);
                });
            }
        })

        this.locationSub = this.sharedService.currentLoggedInLocation.subscribe(location => {
            if (location) {
                this.destroySocket();
                this.initializeSocket(this.userService.userSubject.value);
            }
        });


        this.dialogService = dialogService;
    }

    initializeSocket(user: User) {
        if (this.socket) {
            this.destroySocket();
        }
        this.socket = new WebSocket(user);
        this.socketSubject.next(this.socket);
    }

    destroySocket() {
        if (this.socket) {
            this.socket.destroy();
            this.socket = undefined;
            this.socketSubject.next(undefined);
        }
    }

    ngOnDestroy() {

        this.destroySocket();

        if (this.socketSub) {
            this.socketSub.unsubscribe();
        }

        if (this.locationSub) {
            this.locationSub.unsubscribe();
        }
    }

    sendMessage(message: string, recipients) {
        if (!recipients?.length) {
            this.socket.emit('messageToAll', {
                message
            });
        } else {
            this.socket.emit('message', {
                message, recipients
            });
        }
    }

    displayMessage(notification: Notification) {
        const id = `${notification.type}-${CommonHelpers.getCurrentTimestamp()}`;
        const closeToast = () => {
            const toast = document.getElementById(id)
                .parentElement.getElementsByClassName('p-toast-icon-close')[0] as HTMLElement;
            if (toast) {
                toast.click();

            }
        }
        switch (notification.type) {
            case 'NEW_ORDER': {
                this.sharedService.addMessage(notification.message, 'info', notification.title);
                break;
            }
            case 'REMIND_RETRIEVABLE_ORDERS': {
                this.messageService.add(<any>{
                    key: 'callback-toast',
                    id,
                    summary: notification.title,
                    detail: notification.message,
                    severity: 'warn',
                    sticky: true,
                    okBtnLabel: 'Retrieve',
                    callback: () => {
                        closeToast();
                        this.ordersService.updateManyStatus(notification.data.ids, OrderStatus.taken).then(() => {
                            this.sharedService.addMessage(`Retrieved ${notification.data.ids.length} order(s)!`);
                        });
                    },
                    secondButton: {
                        label: 'Print',
                        callback: () => {
                            const content = `<h4>${notification.data.name}</h4><p>${notification.message}</p>`;
                            this.sharedService.print(content);
                        }
                    }
                })
                break;
            }
            case 'REMIND_DEBT': {
                this.messageService.add(<any>{
                    key: 'callback-toast',
                    id,
                    summary: notification.title,
                    detail: notification.message,
                    severity: 'warn',
                    sticky: true,
                    okBtnLabel: 'Pay',
                    callback: () => {
                        closeToast();
                        for (const debt of notification.data.debts) {
                            this.ordersService.payOrderDebt(<any>{}, debt, this.dialogService, this.sharedService,
                                this.userService, this.dialogService);
                        }
                    }
                });
                break;
            }
            case 'URGE_PICKING_REQUEST': {
                this.messageService.add(<any>{
                    key: 'callback-toast',
                    id,
                    summary: notification.title,
                    detail: notification.message,
                    severity: 'warn',
                    sticky: true,
                    okBtnLabel: 'Review',
                    callback: () => {
                        closeToast();
                        this.router.navigate(['/home/pickup-orders'], {
                            queryParams: {search: notification.data}
                        }).then(() => {
                        });
                    }
                });
                break;
            }
            case 'NEW_COMMENT': {
                this.messageService.add(<any>{
                    key: 'callback-toast',
                    id,
                    summary: notification.title,
                    detail: notification.message,
                    severity: 'warn',
                    sticky: true,
                    okBtnLabel: 'View',
                    callback: () => {
                        closeToast();
                        this.router.navigate(['/home/orders', notification.data?.orderId])
                            .then(() => {
                            });
                    }
                });
                break;
            }
            default: {
                this.messageService.add(<any>{
                    key: 'callback-toast',
                    id,
                    summary: notification.title,
                    detail: notification.message,
                    severity: 'warn',
                    sticky: true,
                    okBtnLabel: 'Got it!',
                    callback: () => {
                        closeToast();
                    }
                });
                break;
            }
        }
        this.sharedService.notificationList.unshift({
            icon: commonConstants.NOTIFICATION_ICON_MAP[notification.type],
            title: notification.title,
            message: notification.message,
            createdAt: new Date()
        });
    }

}
