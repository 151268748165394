import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInputComponent } from './user-input.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    UserInputComponent
  ],
  exports: [
    UserInputComponent
  ],
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule
  ]
})
export class UserInputModule { }
