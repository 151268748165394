
import { FilmItem } from './FilmItem';
export interface FilmOption {
  id: string;
  name: string;
  shortName: string;
  code: string;
  order: number;
  type: string;
  meta_price: {
    id: string;
    price: number;
  };
  film_items: FilmItem[];
  mandatory: boolean;
  orderType: 'FILM' | 'PRINT';
}
