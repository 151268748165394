import {OnInit, OnDestroy, AfterViewInit, Directive} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, ValidatorFn, AbstractControl} from '@angular/forms';
import {cloneDeep} from 'lodash';

import {Message, ConfirmationService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subscription} from 'rxjs';
import {FormControlDecorator} from './form-control-decorator';
import {SharedService} from '../../core/service/shared.service';
import {Utils} from '../../core/helper';


export interface IDynamicRule {
  changeField: string,
  dependedField: string,
  validatorsTrue: any[],
  validatorsFalse: any[],
  trueValue: any,
  emitEvent?: boolean
}


@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BaseFormComponent<T> implements OnInit, OnDestroy {

  originalData: T = <T>{};
  data: T = <T>{};

  public formGroup: UntypedFormGroup;
  rules: any = {};
  dynamicRules: IDynamicRule[] = [];
  superValidators: ValidatorFn | ValidatorFn[];
  valueChangeSubs: Subscription[] = [];

  abstract submitSuccess();

  protected constructor(protected formBuilder: UntypedFormBuilder, protected sharedService: SharedService,
                        protected confirmationService: ConfirmationService) {

  }

  public ngOnInit() {
    this.prepareForm();
    this.prepareData();
  }

  public ngOnDestroy() {
    this.valueChangeSubs.forEach(x => x.unsubscribe());
  }

  public prepareData(newData?: T) {
    if (Utils.isDefinedAndNotNull(newData)) {
      this.originalData = newData;
    } else {
      this.originalData = cloneDeep(this.formGroup.value);
    }
    this.data = this.formGroup.value;
    this.valueChangeSubs.push(this.formGroup.valueChanges.subscribe((data) => {
      this.data = this.formGroup.value;
      this.onValueChanges(data);
    }));
  }

  public onValueChanges(data) {
  }

  public prepareForm() {
    this.formGroup = this.configureFormGroup();
    this.resetDirty();
  }

  get isDiff(): boolean {
    return !Utils.isEqual(this.originalData, this.data);
  }

  public checkFormNotValid(): boolean {
    for (const item in this.formGroup.controls) {
      if (this.formGroup.controls[item]) {
        (<UntypedFormGroup>this.formGroup.controls[item]).updateValueAndValidity();
      }
    }
    return this.formGroup.invalid;
  }


  public submit() {
    this.formGroup.markAsTouched();
    if (this.checkFormNotValid()) {
      if (this.formGroup.errors) {
        this.showErrorMessages(this.formGroup.errors);
      }

      this.validateControls(this.formGroup.controls);
      if (this.formGroup.controls && this.formGroup.errors) {
        this.showErrorMessages(this.formGroup.errors);
      }

      return;
    }
    this.data = this.formGroup.value;
    this.submitSuccess();
    return false;
  }

  validateControls(controls: { [key: string]: AbstractControl }) {
    for (const item of Object.keys(controls)) {
      controls[item].markAsTouched();
      controls[item].markAsDirty();
      if (controls[item]['controls']) {
        this.validateControls(controls[item]['controls']);
      }
    }
  }

  protected configureFormGroup(): UntypedFormGroup {
    const rules: any = {};
    Object.assign(rules, this.rules);
    const formGroup = this.formBuilder.group(rules, {validators: this.superValidators});
    this.dynamicRules.forEach(x => {
      this.valueChangeSubs.push(formGroup.get(x.changeField).valueChanges.subscribe(value => {
        formGroup.get(x.dependedField).clearValidators();
        // tslint:disable-next-line:triple-equals
        const required = value == x.trueValue;
        if (required) {
          FormControlDecorator.setValidators(formGroup.get(x.dependedField), x.validatorsTrue)
        } else {
          FormControlDecorator.setValidators(formGroup.get(x.dependedField), x.validatorsFalse);
        }
        formGroup.get(x.dependedField).updateValueAndValidity({emitEvent: x.emitEvent});
        return;
      }));
    });
    return formGroup;
  }


  private showErrorMessages(errors) {
    const msg = Object.keys(errors).map(err => <Message>{
      severity: 'error', summary: 'Error',
      detail: errors[err].errorMessage
    });
    // this.messages.push(...msg);
    this.sharedService.addMessage(msg.map(i => i.detail).join('\n'), 'error');
  }

  closeModal(ref: DynamicDialogRef) {
    if (this.isDiff) {
      this.confirmationService.confirm({
        message: 'You have unsaved changes. Are you sure to leave?',
        accept: () => ref.close()
      })
    } else {
      ref.close();
    }
  }

  /**
   * Reset the form.
   */
  public reset(): void {
    this.formGroup.reset();
  }


  /**
   * Reset state of formGroup back to pristine.
   */
  public resetDirty(): void {
    this.formGroup.markAsPristine();
  }

  public getDirtyKeys() {
    const keys = [];
    Object.keys(this.formGroup.controls).forEach(i => {
      const currentControl = this.formGroup.controls[i];
      if (currentControl.dirty) {
        keys.push(i);
      }
    });
    return keys;
  }
}
