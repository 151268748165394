<div class="row no-gutters flex-tag-list">
  <div #control *ngFor="let item of shortList" class="col-md-{{option.from}} flex-tags"
       (mouseenter)="onHover(control, item)" (mouseleave)="onHover(control, item)">
    <span class="badge badge-pill"
          [ngStyle]="{'background-color': get(item, option.stylePath)?.background || '#6c757d', 'color': get(item, option.stylePath)?.color || '#fff'}"
          pTooltip="{{get(item, option.fullTextField)}} {{option.extraContent && option.extraContent.values[get(item, option.shortTextField)] ? get(item, option.extraContent.contentField) : ''}}"
          showDelay="500">
      {{get(item, option.shortTextField)}}{{option.extraContent && includes(this.option.extraContent.values, get(item, this.option.extraContent.comparedField)) ? ' (' + get(item, option.extraContent.contentField) + ')' : ''}}</span>
  </div>
</div>
