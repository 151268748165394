<div [ngClass]="styleClass" class="form-group row">
  <div class="col-md-12" [ngClass]="containerClass" #inputGroup>
    <label *ngIf="showLabel && label" [for]="controlName.name">{{label}}<span class="required-asterisk"
        [required-for]="{ formControl: controlName.control, name: controlName.name }"
        *ngIf="controlName && controlName.enabled">
      </span>
    </label>
    <ng-content></ng-content>
  </div>

  <div class="col-md-12">
    <span [hidden]="hidden" class="alert-danger control-validation">
      {{errorMessage}}
    </span>
  </div>

</div>
