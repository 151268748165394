import {Pipe, PipeTransform} from '@angular/core';
import {words} from 'lodash';

@Pipe({
    name: 'paymentMethod'
})
export class PaymentMethodPipe implements PipeTransform {

    transform(payment_method: string, spanClass = '', display = ''): string {
        if (!payment_method) {
            return null;
        }
        const methodDetails = payment_method.split('-');
        const result = {
            label: `${words(methodDetails[0]).join(' ')}${methodDetails[1] ? ` (${methodDetails[1]})` : ''}`,
            icon: null
        }

        let ngClass;
        switch (methodDetails[0]) {
            case 'cash':
                result.icon = 'far fa-money-bill-alt';
                ngClass = 'badge-success';
                break;
            case 'bankTransfer':
                result.icon = 'far fa-credit-card';
                ngClass = 'badge-success';
                break;
            case 'other':
                result.icon = 'fas fa-question';
                ngClass = 'badge-success';
                break;
            case 'unpaid':
                result.icon = 'fas fa-exclamation-circle';
                ngClass = 'badge-danger';
                break;
            case 'debt':
                result.icon = 'fas fa-hand-holding-usd';
                ngClass = 'badge-warning';
                break;
            case 'paid':
                result.icon = 'fas fa-check';
                ngClass = 'badge-success';
                break;
        }
        return `<span class="badge payment-method center-element text-truncate ${ngClass} ${spanClass} ${display}"><i
      class="${result.icon} ${display}"></i>${result.label}</span>`;
    }

}
