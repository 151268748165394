import {OrderStatus} from '../objects/models';
import {PaymentMethod} from '../enum';

export const dateTimeFormat = 'DD/MM/YYYY HH:mm';
export const dateFormat = 'DD/MM/YYYY';
export const dateOnlyFormat = 'DDMM';
export const monthYearFormat = 'MM/YYYY';
export const monthYearDisplayFormat = 'MMMM YYYY';
export const timeFormat = 'HH:mm';

export const phoneNumberFormatRegex = /^[0-9]{9,12}$/;
export const orderNumberPartsRegex = /^(P?)(\d{2})(\d{2})(\d{3})$/;

export const option_codes = {
  'is_dev_and_scan': 'is_dev_and_scan',
  'is_scan': 'is_scan',
  'is_dev': 'is_dev',
  'is_border': 'is_border',
  'is_high_res': 'is_high_res',
  'is_push': 'is_push'
}


export const errorMessages = {
  required: '{0} is required',
  email: 'Email format is invalid',
  phone: 'Phone number is invalid',
  min_products: 'Please select at least {0} item!',
  minArray: 'Please select at least {0} item!',
  required_group: 'Please fill in all the mandatory fields!',
  minlength: '{0} must be at least {1} characters',
  max: 'Max value is {0}',
  min: 'Min value is {0}',
  pattern: '{0} has invalid pattern',
}

export const extraOptions = [
  option_codes.is_border,
  option_codes.is_high_res,
  option_codes.is_push,
]

export const labName = 'LLAB';

export const CONFIG_CODES = {
  SHARING_ACCOUNTS: 'SHARING_ACCOUNTS',
  DAYS_RETRIEVE: 'DAYS_RETRIEVE',
  FAQ_EN: 'FAQ_EN',
  FAQ_VI: 'FAQ_VI',
  FIRST_ORDER_BONUS: 'FIRST_ORDER_BONUS',
  SHIPPING_AVAILABLE: 'SHIPPING_AVAILABLE',
  EMAIL_SHARING: 'email-sharing',
  APP_DISCOUNT: 'APP_DISCOUNT',
  APP_DISCOUNT_ON: 'APP_DISCOUNT_ON',
  BANNERS: 'BANNERS'
};

export const STORE_CODES = {
  TOKEN: 'llab_tk',
};


export const paymentMethods = [PaymentMethod.CASH,
  PaymentMethod.CARD_TECHCOMBANK,
  PaymentMethod.CARD_VIETCOMBANK,
  PaymentMethod.CARD_MOMO,
  PaymentMethod.OTHER,
  PaymentMethod.UNPAID,
];

export const paymentMethodsNameMap = {
  [PaymentMethod.CASH]: 'Cash',
  [PaymentMethod.CARD_TECHCOMBANK]: 'Card (Techcombank)',
  [PaymentMethod.CARD_VIETCOMBANK]: 'Card (Vietcombank)',
  [PaymentMethod.CARD_MOMO]: 'MoMo',
  [PaymentMethod.OTHER]: 'Other',
}

export const paymentMethodDisplayNames = [
  paymentMethodsNameMap[PaymentMethod.CASH],
  paymentMethodsNameMap[PaymentMethod.CARD_TECHCOMBANK],
  paymentMethodsNameMap[PaymentMethod.CARD_VIETCOMBANK],
  paymentMethodsNameMap[PaymentMethod.CARD_MOMO],
  paymentMethodsNameMap[PaymentMethod.OTHER],
];

export const bankList = [
  'Techcombank', 'Vietcombank', 'MoMo'
]

export const SCANNER = {
  FRONTIER: 'FRONTIER',
  NORITSU: 'NORITSU'
}

export const SCOPE = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
}

export const ORDER_TYPE = {
  FILM: 'FILM',
  PRINT: 'PRINT'
}

export const STEP_TYPES = {
  LIST: 'LIST',
  FORM: 'FORM',
  SUMMARY: 'SUMMARY'
}

export const GENDERS = ['male', 'female', 'other'];

export const RETRIEVAL_METHOD = {
  KEEP: 'KEEP',
  DESTROY: 'DESTROY',
  DELIVER: 'DELIVER'
}

export const NOTIFICATION_ICON_MAP = {
  NEW_ORDER: 'far fa-file',
  REMIND_RETRIEVABLE_ORDERS: 'far fa-hand-lizard',
  REMIND_DEBT: 'fas fa-search-dollar',
  INTERNAL_MESSAGE: 'far fa-comment-dots',
}





